<template>
  <div id="VjRadioNumber">
    <el-radio-group v-bind="combineConfig" v-model="radioType">
      <el-radio :label="item.value" v-for="(item, key) in combineConfig.options" :key="key">{{ item.label }} </el-radio>
    </el-radio-group>
    <el-date-picker
      class="number-cmp"
      valueFormat="yyyy-MM-dd HH:mm:ss"
      type="datetime"
      v-if="isTypeOf('date') && radioType === combineConfig.visibleVal"
      v-model="radioValue"
      v-bind="combineConfig" />
    <el-input-number
      class="number-cmp"
      v-else-if="radioType === combineConfig.visibleVal"
      v-model="radioValue"
      v-bind="combineConfig" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions } from "vue";
import Component, { mixins } from "vue-class-component";
import { FormMixin } from "./mixins/form-mixin";
import { VInput, VNumber, VRadioCmp } from "./config/form";
import { Prop, Watch } from "vue-property-decorator";
import logger from "vuex/dist/logger";
import { VModelSync } from "@/components/vj-lib/utils";

@Component({
  watch: {
    radioType: "updateData",
    radioValue: "updateData",
  },
})
export default class VjRadioCmp extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VjRadioNumber";
  @Prop() cmp!: string;
  @VModelSync() fakeValue!: any;
  radioType = 0;
  radioValue = "";
  get combineConfig() {
    return this.combineWith<VRadioCmp.configType>(VRadioCmp.config);
  }
  updateData() {
    this.$emit("input", [this.radioType, this.radioValue]);
  }
  @Watch("fakeValue")
  changeProp() {
    [this.radioType, this.radioValue] = this.fakeValue || [];
  }
}
</script>

<style scoped lang="scss">
#VjRadioNumber {
  .number-cmp {
    margin-left: 20px;
  }
}
</style>
