<template>
  <div id="loginContainer" @click="showVerify = false">
    <div class="wrapper fadeInDown">
      <form @submit="void 0" id="formContent" v-loading="loading">
        <h2 class="active">请登录您的帐号</h2>
        <div class="fadeIn first">
          <img src="../../assets/img/login_head_icon.svg" id="icon" alt="User Icon" />
        </div>
        <div>
          <el-form style="padding: 0 70px; margin-top: 50px">
            <el-form-item>
              <el-input clearable v-model="form.username" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item>
              <el-input show-password clearable v-model="form.password" placeholder="请输入密码" />
            </el-form-item>
          </el-form>
          <div class="submit-wrap">
            <verify ref="verify" class="c-verify" v-show="showVerify" verify-type="LOGIN" @change="login" />
            <input type="submit" @click.prevent.stop.self="showVerify = true" class="fourth" value="登录" />
          </div>
        </div>
        <div id="formFooter">
          <a class="underlineHover">忘记密码</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { requestByPost } from "@/utils/request";
import Verify from "@/components/verify/index";
import { BACKEND_PATH_PURE, customStore } from "@/config/properties";

export default {
  name: "loginContainer",
  components: { Verify },
  data() {
    return {
      loading: false,
      showVerify: false,
      userNameFocus: false,
      passWordFocus: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  computed: {},
  methods: {
    async login({ status, uuid }) {
      if (!status) return this.$message.error("校验失败");
      this.showVerify = false;
      this.loading = true;
      const { username, password } = this.form;
      const {
        data: { token, sysUser = {} },
      } = await requestByPost(
        `${BACKEND_PATH_PURE}/passport/ajaxLogin`,
        {
          //
          loginName: username,
          password,
        },
        { headers: { uuid } }
      ).finally(() => {
        this.loading = false;
        this.$refs.verify.init();
      });
      customStore.userInfo = sysUser;
      localStorage.setItem("userInfo", JSON.stringify(sysUser));
      localStorage.setItem("Authorization", token);
      this.$message.success("登录成功！");
      await this.initPreData();
      return this.$router.replace("/");
      // this.$router.go(-1);
    },
  },
  created() {
    // this.login();
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/login";
@import "~assets/common.scss";

.c-verify {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -104%);
  z-index: 10;
}

.submit-wrap {
  position: relative;
}

.captcha {
  display: inline-block;
  cursor: pointer;
  width: 100px;
  border: 1px solid #ccc;
  margin-left: 10px;
  border-radius: 3px;
  overflow: hidden;
}
</style>
