import VJForm from "./vj-form.vue";
import VJTable from "./vj-table.vue";
import VJUplaod from "./vj-upload.vue";
import ElementUI from "element-ui";
import Vue from "vue";
Vue.use(ElementUI);
export default function install(Vue, options) {
  Vue.prototype.$uploadPath = options.uploadPath;
  Vue.component(VJForm.name, VJForm);
  Vue.component(VJTable.name, VJTable);
  Vue.component("vj-upload", VJUplaod);
}
