import { ComponentOptions } from "vue/types/options";
import Vue from "vue";
import Component from "vue-class-component";
import { genMenuCfg } from "@/components/vj-lib/utils";
import { routeGuard } from "@/utils/permission";
const icons = [
  "el-icon-platform-eleme",
  "el-icon-eleme",
  "el-icon-delete-solid",
  "el-icon-delete",
  "el-icon-s-tools",
  "el-icon-setting",
  "el-icon-user-solid",
  "el-icon-user",
  "el-icon-phone",
  "el-icon-phone-outline",
  "el-icon-more",
  "el-icon-more-outline",
  "el-icon-star-on",
  "el-icon-star-off",
  "el-icon-s-goods",
  "el-icon-goods",
  "el-icon-warning",
  "el-icon-warning-outline",
  "el-icon-question",
  "el-icon-info",
  "el-icon-remove",
  "el-icon-circle-plus",
  "el-icon-success",
  "el-icon-error",
  "el-icon-zoom-in",
  "el-icon-zoom-out",
  "el-icon-remove-outline",
  "el-icon-circle-plus-outline",
  "el-icon-circle-check",
  "el-icon-circle-close",
  "el-icon-s-help",
  "el-icon-help",
  "el-icon-minus",
  "el-icon-plus",
  "el-icon-check",
  "el-icon-close",
  "el-icon-picture",
  "el-icon-picture-outline",
  "el-icon-picture-outline-round",
  "el-icon-upload",
  "el-icon-upload2",
  "el-icon-download",
  "el-icon-camera-solid",
  "el-icon-camera",
  "el-icon-video-camera-solid",
  "el-icon-video-camera",
  "el-icon-message-solid",
  "el-icon-bell",
  "el-icon-s-cooperation",
  "el-icon-s-order",
];

@Component
export class SliderMixin extends Vue {
  get dirNameDict() {
    return {
      permission: "权限管理",
    };
  }
  get subMenus() {
    let routes = this.$router.getRoutes();
    routes = routes.filter(({ meta }) => meta.title);
    return routes.map(({ path, meta }) => ({ path, title: meta.title }));
  }
  get subVisibleMenus() {
    let routes = this.$router.getRoutes();
    routes = routes.filter(({ meta }) => meta.showMenu);
    return routes.map(({ path, meta }) => ({ path, title: meta.title }));
  }

  get menusCfg() {
    let routes = this.$router.getRoutes();
    routes = routes.filter(({ meta }) => meta.showMenu);
    const subMenusFromDir = (key) => routes.filter(({ path }) => path.includes(key));
    //分离出有父菜单
    const dirMenus = Object.keys(this.dirNameDict).filter((key) => subMenusFromDir(key).length);
    //将父菜单对应的子菜单注入进来
    let menus = dirMenus.map((key) => [
      "el-icon-s-promotion",
      this.dirNameDict[key],
      key,
      subMenusFromDir(key).map(({ path, meta }) => ["el-icon-s-promotion", meta.title, path]),
    ]);
    //将没有父菜单的菜单加入进来
    menus = menus.concat(
      routes
        .filter(({ path }) => !Object.keys(this.dirNameDict).some((key) => path.startsWith(`/${key}`)))
        .map(({ path, meta }) => ["el-icon-s-promotion", meta.title, path])
    );
    return genMenuCfg(menus, "", icons);
  }
  get canBeAccessMenusCfg() {
    const { canActivePath = [] } = routeGuard.store;
    let menus = genMenuCfg(
      (function closure(pathList) {
        return pathList.map(({ name, child, href }) => ["xx", name, href, closure(child)]);
      })(canActivePath),
      "",
      icons
    );

    menus = menus.map(({ children, ...rest }) => ({
      children: children.filter(({ path }) => this.subVisibleMenus.some((item) => item.path === path)),
      ...rest,
    }));
    const { children = [] } = menus.find(({ name }) => name === "首页") ?? [];
    menus = menus.filter(({ name }) => name !== "首页");
    return menus.concat(children.map((item) => ({ ...item, children: null })));
  }
}
