<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/删除"
    id="PermissionPop">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { PermissionForm, UserForm } from "@/config/edit-forms";
import { hookSubmit } from "@/components/vj-lib/utils";
import { SliderMixin } from "@/plugins/mixins/slider";

@Component({})
export default class PermissionPop extends Mixins(FormMixin, SliderMixin) {
  private showPop = false;
  @Prop({ default: () => [] }) menuList!: any[];
  cfgListGenerator = PermissionForm;

  showPopup(data = {}, isNew) {
    this.cfgList = this.cfgListGenerator();
    this.showPop = true;
    this.isNew = isNew;
    this.id = data["id"];
    this.formData = data;
    this.updateMenuTypeVisible();
    this.initSearchDict();
  }
  updateMenuTypeVisible() {
    const targetOpt = this.cfgList.find(({ name }) => name === "type");
    targetOpt.config.disabled = !!this.id;
  }
  @Watch("formData.href")
  onHrefChange(val) {
    const { title } = this.subMenus.find(({ path }) => path === val) ?? {};
    if (!title) return;
    this.formData["name"] = title;
  }

  async initSearchDict() {
    const options = this.menuList.map(({ name, id }) => ({ label: name, value: id }));
    updateFormSelectOption(this.cfgList, "pid", options);
    const subMenuOptions = this.subMenus.map(({ path, title }) => ({ label: title, value: path }));
    updateFormSelectOption(this.cfgList, "href", subMenuOptions);
  }
  @hookSubmit()
  async onSubmit() {
    await requestByPost(`sysMenu/saveUpdate`, {
      ...this.formData,
      id: this.isNew ? null : +this.id,
    });
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  created() {}
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#PermissionPop {
}
</style>
