<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/删除"
    id="AccountPopup">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { AccountForm, UserForm } from "@/config/edit-forms";
import { hookSubmit } from "@/components/vj-lib/utils";

@Component({})
export default class AccountPopup extends Mixins(FormMixin) {
  showPop = false;
  cfgList = AccountForm;

  showPopup(data = {} as any, isNew) {
    this.showPop = true;
    this.isNew = isNew;
    this.id = data["id"];
    this.formData = data;
    this.formData.roleList = data.roleList?.map(({ id }) => id) || [];
  }

  async initSearchDict() {
    const { data = [] } = await requestByPost(`sysRole/pageList/1/999`);
    const options = data.map(({ name, id }) => ({ label: name, value: id }));
    updateFormSelectOption(this.cfgList, "roleList", options);
  }

  @hookSubmit()
  async onSubmit() {
    await warnConfirm();
    const { roleList, ...restForm } = this.formData;
    await requestByPost(`sysUser/saveUpdate`, {
      id: this.isNew ? null : +this.id,
      isAdmin: +roleList.includes(1),
      roleList: roleList.map((id) => ({ id })),
      ...restForm,
    });
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  created() {
    this.initSearchDict();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#AccountPopup {
}
</style>
