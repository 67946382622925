<template>
  <div id="AppLogo">
    <img v-if="logo" alt="" :src="logo" class="sidebar-logo" />
    <h1 class="sidebar-title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "AppLogo",
  data() {
    return {
      title: "管理后台",
      logo: require("@/assets/img/lo.png"),
    };
  },
  methods: {},
  computed: {},
  created() {},
};
</script>

<style scoped lang="scss">
@import "../../assets/common";
@import "../../assets/scss/variables";

#AppLogo {
  width: $sideBarWidth - 1px;
  @include alignCenter;
  padding-left: 18px;
  background-color: #3a3f51;
  border-bottom: 1px solid #454b61;
  box-sizing: border-box;
  .sidebar-logo {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-right: 15px;
  }
  .sidebar-title {
    color: white;
    font-size: 22px;
  }
}
</style>
