<template>
  <el-table-column v-if="customRender" :label="label" v-bind="combineCfg">
    <template slot-scope="{ row }">
      <div v-render-col:[row]="customRender"></div>
    </template>
  </el-table-column>
  <el-table-column v-else-if="cmp" :label="label" v-bind="combineCfg">
    <template slot-scope="{ row }">
      <div v-if="customRender" v-render-col:[row]="customRender"></div>
      <span v-else-if="isTypeOf('multiple')">{{ row[propName[0]] }}（{{ row[propName[1]] }}）</span>
      <p v-else-if="isTypeOf('html')" class="pre-text" v-html="formatter ? formatter(row) : row"></p>
      <span v-else-if="isTypeOf('range')">{{ row[propName[0]] }} - {{ row[propName[1]] }}</span>
      <el-tag v-else-if="isTypeOf('state')">{{ row.state ? "正常" : "禁用" }}</el-tag>
      <el-tag v-else-if="isTypeOf('tag')">{{ formatter ? formatter(row[propName]) : row[propName] }}</el-tag>
      <el-input-number
        :controls="false"
        size="small"
        style="width: 60px"
        v-else-if="isTypeOf('number')"
        @change="$emit('changeNum', $event, propName, row)"
        v-model="row[propName]" />
      <el-radio-group v-else-if="isTypeOf('radio')" @change="$emit('changeRadio', $event, propName, row)" v-model="row[propName]">
        <el-radio :label="item.value" v-for="(item, key) in combineCfg.options" :key="key">{{ item.label }} </el-radio>
      </el-radio-group>
      <el-switch v-else-if="isTypeOf('switch')" @change="onSwitch($event, propName, row)" :value="!!(row && row[propName])" />
      <el-image
        v-else-if="isTypeOf('img')"
        style="width: 70px; height: 70px"
        :src="getPic(row[propName])"
        fit="contain"></el-image>
    </template>
  </el-table-column>
  <el-table-column :prop="propName" v-else :label="label" v-bind="combineCfg" :formatter="formatter" />
</template>

<script lang="ts">
import Component, { mixins } from "vue-class-component";
import Vue, { ComponentOptions } from "vue";
import { Mixins, Prop } from "vue-property-decorator";
import { FormMixin } from "./mixins/form-mixin";
import { ColConfig, DefaultTableColProps } from "./config/table";

@Component({
  directives: {
    renderCol: {
      inserted(el, { arg, value }) {
        if (typeof value !== "function") return;
        const temp = document.createElement("tmp");
        el.appendChild(temp);
        el["vm"] = new Vue({
          el: temp,
          render(h) {
            return value(h, arg);
          },
        });
      },
      update(el, { arg, value }) {
        if (typeof value !== "function") return;
        el["vm"].$destroy();
        el["vm"] = new Vue({
          el: el.firstChild as any,
          render(h) {
            return value(h, arg);
          },
        });
      },
    },
  },
})
export default class VjTableCol extends Mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJTableCol";
  @Prop() colStyle!: any;
  @Prop() cmp!: string;
  @Prop() customRender!: Function;
  @Prop() label!: string;
  @Prop() formatter!: Function;
  @Prop() propName!: string | string[];

  async onSwitch(val, key, row) {
    await this.$confirm("确认执行此操作吗？", { type: "warning" });
    row[key] = val;
    this.$emit("switch", val, key, row, () => (row[key] = !val));
  }

  get combineCfg() {
    const otherCfg = this.isTypeOf("number") ? { width: 200 } : { minWidth: 150 };
    return this.combineWith<ColConfig>(DefaultTableColProps, otherCfg, this.colStyle);
  }
}
</script>

<style scoped lang="scss">
.pre-text {
}
</style>
