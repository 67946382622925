<template>
  <div id="VJSelect">
    <el-radio-group v-on="bindListeners" v-if="isTypeOf('radio')" v-model="fakeValue" v-bind="combineConfig">
      <el-radio :label="item.value" v-for="(item, key) in combineConfig.options" :key="key">{{ item.label }} </el-radio>
    </el-radio-group>
    <el-checkbox-group v-on="bindListeners" v-else-if="isTypeOf('checkbox')" v-model="fakeValue" v-bind="combineConfig">
      <el-checkbox v-for="(item, key) in combineConfig.options" :label="item.value" :key="key">{{ item.label }}</el-checkbox>
    </el-checkbox-group>
    <el-switch
      v-else-if="isTypeOf('switch')"
      :active-text="combineConfig.options[0] && combineConfig.options[0].label"
      v-model="fakeValue"
      v-on="bindListeners" />
    <el-cascader v-else-if="isTypeOf('cascader')" v-model="fakeValue" v-bind="combineConfig" v-on="bindListeners" />
    <el-select clearable v-on="bindListeners" v-else v-model="fakeValue" v-bind="combineConfig">
      <el-option :value="item.value" v-for="(item, key) in combineConfig.options" :key="key" :label="item.label" />
    </el-select>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { FormMixin } from "./mixins/form-mixin";
import { Prop } from "vue-property-decorator";
import { VInput, VSelect } from "./config/form";
import { VModelSync } from "@/components/vj-lib/utils";

@Component
export default class VJSelect extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJNumber";
  @Prop() cmp!: string;
  @Prop() propName!: string;
  @VModelSync() fakeValue!: string;
  get combineConfig() {
    return this.combineWith(VSelect.config) as VSelect.configType;
  }
}
</script>

<style scoped lang="scss">
#VJSelect {
}
</style>
