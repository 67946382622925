<template>
  <el-dialog
    :close-on-click-modal="false"
    width="900px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/编辑"
    id="SysPayEditPopup">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { SysPayForm, UserForm } from "@/config/edit-forms";
import { cloneDeep, hookSubmit } from "@/components/vj-lib/utils";
import { customStore } from "@/config/properties";

@Component({})
export default class SysPayEditPopup extends Mixins(FormMixin) {
  showPop = false;
  cfgList = SysPayForm;
  oldData = {};
  isEchoData = false;

  showPopup(data = {}, isNew) {
    this.showPop = true;
    this.oldData = cloneDeep(data);
    this.isNew = isNew;
    this.id = data["id"];
    //只有编辑才是回显状态
    this.isEchoData = !!this.id;
    this.formData = data;
    this.initSearchDict();
  }

  async initSearchDict() {
    const options = customStore.paymentServices.map(({ type, name }) => ({ label: name, value: type }));
    updateFormSelectOption(this.cfgList, "paymentType", options);
  }

  @hookSubmit()
  async onSubmit() {
    this.completionFormKeySymbol("countryCodes");
    this.completionFormKeySymbol("currencies");
    await requestByPost(`pcPaymentType/edit`, {
      ...this.oldData,
      ...this.formData,
      id: this.isNew ? null : +this.id,
    });
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  completionFormKeySymbol(key) {
    const val = this.formData[key];
    if (!val || /^,(.*),$/g.test(val)) return;
    this.formData[key] = val.replace(/^,?(.*),?$/g, ",$1,");
  }

  //当服务商切换时 获取对应的收款账号  设置为第一个
  @Watch("formData.paymentType")
  @hookSubmit()
  async onPaymentTypeChange(val, oldVal) {
    if (!val) return;
    const res = await requestByPost(`pcReceiptAccount/getList`, { paymentType: val, status: 1 });
    const options = res.data.map(({ receiptAccount, id }) => ({ label: receiptAccount, value: id }));
    updateFormSelectOption(this.cfgList, "receiptAccountIds", options);
    //如果是回显状态 则不修改 仅将状态改变
    if (this.isEchoData) return (this.isEchoData = false);
    //每当切换服务商时将账号选择第一个  注意 回显时也会调用这个接口 这时候不能将其设置为第一个
    this.formData.receiptAccountIds = [options?.[0]?.value];
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#SysPayEditPopup {
}
</style>
