<template>
  <div id="BodyContainer">
    <header class="b-header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item style="cursor: pointer" v-if="customStore.backTitle" @click.native="backHandle">{{
          customStore.backTitle
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <slot name="features" />
    </header>
    <main class="b-content">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { customStore } from "@/config/properties";

@Component
export default class BodyContainer extends Vue {
  name = "bodyContainer";
  get title() {
    return this.$route.meta?.title;
  }
  backHandle() {
    this.$popRoute();
    customStore.backTitle = "";
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/common";
#BodyContainer {
  box-sizing: border-box;
  .b-header {
    background: #eceef2;
    margin-bottom: 24px;
    padding: 20px 20px;
    position: relative;
    @include flex-between;
    align-items: center;
    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: "";
      width: 6px;
      height: 26px;
      background: #43c8b2;
    }
  }
  .b-content {
    padding: 0 24px;
  }
}
</style>
