<template>
  <div id="CurrencyList">
    <body-container>
      <section slot="features">
        <el-button @click="currencyPopup.onShow()">新增</el-button>
      </section>
      <search-form-container @search="onSearch">
        <el-input slot="input" v-model="keyWords" placeholder="请输入货币名称" />
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <vj-table @loadTable="onSearch" :page-obj="pageObj" :loading="loading" :cfg-list="cfgList" :table-data="tableData">
        <template #action="item">
          <text-button @click="currencyPopup.onShow(item)" text="编辑" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <currency-popup @refresh="onSearch()" ref="currencyPopup" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByDel, requestByPost } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import CurrencyPopup from "@/components/popup/currency-popup.vue";
import { warnConfirm } from "@/utils/logic";
import { hookLoading, hookSubmit } from "@/components/vj-lib/utils";
import { CurrencySH } from "@/config/search-forms";
import { CurrencyTB } from "@/config/tables";

export const title = "货币列表";
@Component({
  components: { CurrencyPopup, TextButton, SearchFormContainer, BodyContainer },
})
export default class CurrencyList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  name = "CurrencyList";
  cfgList = CurrencyTB;
  searchCfg = CurrencySH();
  @Ref() currencyPopup;

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign({ name: this.keyWords }, this.searchForm);
    const res = await requestByPost(`exchangeRate/pageList/${num}/${size}`, params);
    this.tableData = res.data;
    return res;
  }

  @hookSubmit()
  async del(id) {
    await warnConfirm();
    await requestByDel(`exchangeRate/${id}`);
    this.$message.success("操作成功！");
    this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#CurrencyList {
}
</style>
