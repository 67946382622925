<template>
  <div id="BusinessList">
    <body-container>
      <section slot="features">
        <el-button @click="editRef.showPopup({}, 1)">新增</el-button>
      </section>
      <search-form-container @search="onSearch">
        <div slot="input" class="search-input-slot">
          <el-select class="search-input-slot__options" size="small" v-model="selectDict.optionType">
            <el-option value="name" label="商户名称" />
            <el-option value="id" label="商户ID" />
          </el-select>
          <el-input size="small" clearable class="search-input-slot__cmp" v-model="selectDict.keyWords" placeholder="请输入" />
        </div>
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <vj-table
        @switch="onSwitch"
        @loadTable="onSearch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :table-data="tableData">
        <template #action="item">
          <text-button @click="editRef.showPopup(item)" text="编辑" />
          <text-button @click="editRef.showPopup(item, 1)" text="复制" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <business-edit-popup ref="EditPopup" @refresh="onSearch" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByDel, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { BusinessTB, UserTB } from "@/config/tables";
import { BusinessSH } from "@/config/search-forms";
import BusinessEditPopup from "@/components/popup/business-edit-popup.vue";

export const title = "商户列表";
@Component({
  components: { BusinessEditPopup, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class BusinessList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  @Ref("EditPopup") editRef!: BusinessEditPopup;
  cfgList = BusinessTB.call(this);
  searchCfg = BusinessSH;
  selectDict: any = { optionType: "name", keyWords: null };
  nav2Page(path, query) {
    this.$pushRoute({ path, query });
  }
  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign(this.selectForm, this.searchForm);
    const res = await requestByPost(`pcMerchant/pageList/${num}/${size}`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }

  async onSwitch(val, name, { id }) {
    await requestByPost(`pcMerchant/updateStatus`, { id, status: +val });
    this.$message.success("操作成功！");
    return this.onSearch(this.pageObj.num, this.pageObj.size);
  }

  @hookLoading()
  async del(id) {
    await warnConfirm();
    await requestByDel(`pcMerchant/${id}`);
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#BusinessList {
}
</style>
