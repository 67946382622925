<template>
  <div id="OrderList" v-loading="loading">
    <body-container>
      <section slot="features">
        <el-button @click="exportFile()">导出</el-button>
      </section>
      <search-form-container @search="onSearch">
        <div slot="input" class="search-input-slot">
          <section class="search-input-slot">
            <el-select class="search-input-slot__options" size="small" v-model="selectDict.optionType">
              <el-option value="payOrderId" label="支付订单号" />
              <el-option value="receiptAccount" label="收款账号" />
              <el-option value="merchantOrderId" label="商户订单号" />
              <el-option value="orderId" label="订单号" />
              <el-option value="refundOrderId" label="退款单号" />
              <el-option value="goodsName" label="商品名称" />
              <el-option value="merchantName" label="商户名称" />
              <el-option value="appName" label="应用名称" />
            </el-select>
            <el-input size="small" clearable class="search-input-slot__cmp" v-model="selectDict.keyWords" placeholder="请输入" />
          </section>
          <section class="search-input-slot" style="margin-left: 20px">
            <el-select class="search-input-slot__options" size="small" v-model="selectDict2.optionType">
              <el-option value="payerName" label="姓名" />
              <el-option value="payerId" label="用户账号" />
              <el-option value="ip" label="下单IP" />
              <el-option value="phone" label="手机号" />
              <el-option value="email" label="邮箱" />
              <el-option value="payerCardNum" label="卡号" />
            </el-select>
            <el-input size="small" clearable class="search-input-slot__cmp" v-model="selectDict2.keyWords" placeholder="请输入" />
          </section>
        </div>
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <vj-table
        :style-config="{ align: 'left' }"
        @loadTable="onSearch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :table-data="tableData">
      </vj-table>
    </body-container>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref, Watch } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { defaultHeaders, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading, hookSubmit } from "@/components/vj-lib/utils";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { OrderTB, UserTB } from "@/config/tables";
import { OrderSH } from "@/config/search-forms";
import axios from "axios";
import { BACKEND_PATH } from "@/config/properties";
import dayjs from "dayjs";

export const title = "订单列表";
@Component({
  components: { UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class OrderList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  cfgList = OrderTB;
  searchCfg = OrderSH();
  selectDict: any = { optionType: "merchantOrderId", keyWords: null };
  selectDict2: any = { optionType: "payerName", keyWords: null };

  get selectForm2() {
    const { optionType, keyWords } = this.selectDict2;
    // const needInteger = optionType.toLowerCase().includes("id");
    // const intKeyWords = isNaN(+keyWords) ? -1 : keyWords;
    return { [optionType]: keyWords };
  }

  @Watch("searchForm.paymentType", { immediate: true })
  async onPaymentTypeChange(paymentType) {
    const { data } = await requestByPost(`pcPaymentType/allList`, { paymentType });
    const options = data.map(({ name }) => ({ label: name, value: name }));
    updateFormSelectOption(this.searchCfg, "paymentTypeNameList", options);
  }

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign({}, this.selectForm, this.selectForm2, this.searchForm);
    if (!params.searchMaxAmount) {
      params.searchMaxAmount = null;
      params.searchMinAmount = null;
    }
    const res = await requestByPost(`pcMerchantOrder/pageList/${num}/${size}`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }

  @hookSubmit()
  async exportFile() {
    const params = Object.assign(this.selectForm, this.selectForm2, this.searchForm);
    if (!params.searchMaxAmount) {
      params.searchMaxAmount = null;
      params.searchMinAmount = null;
    }
    const { searchBeginTime, searchEndTime } = params;
    const isOverThan2Month = dayjs(searchEndTime).diff(dayjs(searchBeginTime), "month") > 2;
    if (isOverThan2Month || !searchBeginTime) return this.$message.warning("最大可导出2个月以内数据");
    const res = await axios.post(`${BACKEND_PATH}/pcMerchantOrder/export`, excludeEmpty(params), {
      headers: defaultHeaders(),
      responseType: "blob",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(res.data);
    a.download = "报表.xlsx";
    a.click();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#OrderList {
}
</style>
