<template>
  <div>
    <el-table v-bind="combineCfg" v-on="$listeners" v-loading="loading" :data="tableData">
      <template v-for="item in cfgList">
        <vj-table-col v-on="$listeners" v-bind="item" />
      </template>
      <el-table-column
        :width="styleConfig.actionWidth"
        fixed="right"
        min-width="160"
        v-if="this.$scopedSlots.action"
        align="center"
        :label="combineCfg.actionLabel || '操作'">
        <template slot-scope="{ row }">
          <slot name="action" v-bind="row" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pagination"
      style="text-align: center; margin: 10px 0"
      background
      layout="prev, pager, next"
      @current-change="$emit('loadTable', $event, pageSize)"
      :current-page.sync="pageObj.num"
      :page-size.sync="pageObj.size"
      :total="pageObj.total">
    </el-pagination>
  </div>
</template>

<script>
import VjTableCol from "./vj-table-col";
import { DefaultTableStyle } from "./config/table";
import { defaultProp } from "@/components/vj-lib/utils";

export default {
  name: "vj-table",
  components: { VjTableCol },
  data() {
    return {
      pageSize: 10,
      pageNum: 0,
    };
  },
  props: {
    tableData: { type: Array, required: true },
    pagination: { type: Boolean, default: true },
    cfgList: { type: Array, required: true },
    pageObj: { type: Object, default: () => ({}) },
    loading: Boolean,
    styleConfig: defaultProp({}),
  },
  computed: {
    combineCfg() {
      return Object.assign({}, DefaultTableStyle, this.styleConfig);
    },
  },
};
</script>

<style scoped></style>
