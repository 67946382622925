<template>
  <div id="VJFile">
    <el-upload
      :action="action"
      :show-file-list="false"
      :headers="headers"
      v-loading="loading"
      :before-upload="() => (loading = true)"
      :on-error="onUploadError"
      :on-success="onUpload">
      <el-button size="small" type="primary">{{ fileName || "点击上传" }}</el-button>
    </el-upload>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import { VFile } from "./config/form";
import emitter from "element-ui/src/mixins/emitter";
import { Prop, Watch } from "vue-property-decorator";
import { Message } from "element-ui";
import { defaultProp, VModelSync } from "@/components/vj-lib/utils";
import { FormMixin } from "@/components/vj-lib/mixins/form-mixin";

@Component({
  mixins: [emitter],
})
export default class VJFile extends FormMixin implements ComponentOptions<Vue> {
  name = "VJFile";
  loading = false;
  fileName = "";
  @VModelSync(defaultProp("")) fakeValue!: any;
  get combineConfig() {
    return this.combineWith<VFile.configType>(VFile.config);
  }
  get action() {
    return this.$uploadPath;
  }
  get headers() {
    const header = {};
    const Authorization = localStorage.getItem("Authorization");
    const gameId = localStorage.getItem("gameId");
    Authorization && (header["Authorization"] = Authorization);
    gameId && (header["gameId"] = gameId);
    return header;
  }
  onUploadError() {
    this.loading = false;
    return Message.error("上传失败！");
  }
  onUpload(res, { name }) {
    if (!res?.data) return this.onUploadError();
    this.fileName = name;
    this.fakeValue = res.data;
    this.loading = false;
    this.$emit("uploaded");
  }
  @Watch("fakeValue")
  valueChange(this: any) {
    this.dispatch && this.dispatch("ElFormItem", "el.form.blur", [this.fakeValue]);
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#VJFile {
}
</style>
