<template>
  <el-menu
    class="c-slider"
    router
    :default-active="activeMenu"
    :collapse="false"
    background-color="#3a3f51"
    text-color="#bfcbd9"
    active-text-color="#409EFF"
    :collapse-transition="false"
    mode="vertical">
    <template v-for="cfg in canBeAccessMenusCfg">
      <el-submenu v-if="cfg.children" :index="cfg.name">
        <span class="c-slider__title" slot="title"><i :class="cfg.icon" />{{ cfg.name }}</span>
        <el-menu-item v-for="(child, key) in cfg.children" :key="key" v-if="hasRoute(child.path)" :index="child.path">
          <span class="c-slider__title" slot="title"><i :class="child.icon" />{{ child.name }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else-if="hasRoute(cfg.path)" :index="cfg.name">
        <span class="c-slider__title" slot="title"><i :class="cfg.icon" />{{ cfg.name }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script lang="ts">
import Component from "vue-class-component";
import Vue from "vue";
import { genMenuCfg } from "@/components/vj-lib/utils";
import { SliderMixin } from "@/plugins/mixins/slider";
import { Mixins } from "vue-property-decorator";

@Component
export default class SliderBar extends Mixins(SliderMixin) {
  get activeMenu() {
    return this.$route.fullPath;
  }

  created() {}
}
</script>

<style scoped lang="scss">
@import "~assets/scss/variables";
@import "~assets/common.scss";
.c-slider {
  width: $sideBarWidth;
  box-sizing: border-box;
  overflow-x: hidden;
  ::v-deep .el-menu-item.is-active {
    background: rgb(46, 50, 65) !important;
  }
  &__title {
    font-size: 16px;
    @include alignCenter;
    i[class^="el-icon-"] {
      font-size: 22px;
    }
  }
}
</style>
