<template>
  <div id="OrderList">
    <body-container>
      <template #features>
        <el-button @click="editPopup.showPopup()">新增</el-button>
      </template>
      <vj-table
        @loadTable="onSearch"
        @switch="onSwitch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :table-data="tableData">
        <template #action="item">
          <el-button type="text" @click="editPopup.showPopup(item)">编辑</el-button>
          <el-button type="text" @click="delHandle(item)">删除</el-button>
        </template>
      </vj-table>
      <account-new-popup :paymentType="type" ref="editPopup" @refresh="onSearch" />
    </body-container>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Prop, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { defaultHeaders, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading, hookSubmit } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { AccountListTB, OrderTB, UserTB } from "@/config/tables";
import { OrderSH } from "@/config/search-forms";
import axios from "axios";
import { BACKEND_PATH, customStore } from "@/config/properties";
import AccountListPopup from "@/components/popup/account/account-list-popup.vue";
import AccountNewPopup from "@/components/popup/account-new-popup.vue";

export const title = "账号列表";
@Component({
  components: { AccountNewPopup, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class OrderList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  cfgList = AccountListTB;
  @Ref()
  editPopup!: AccountNewPopup;
  @Prop()
  type!: string;
  @Prop()
  providerName!: string;

  @hookSubmit()
  async onSwitch(val, name, { id }, resetHandle) {
    try {
      await requestByPost(`pcReceiptAccount/updateStatus`, { id, status: +val });
    } catch (e) {
      resetHandle();
      throw e;
    }
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign({ paymentType: this.type }, this.searchForm);
    const res = await requestByPost(`pcReceiptAccount/getList`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }
  @hookSubmit()
  async delHandle({ id }) {
    await warnConfirm();
    await requestByGet(`pcReceiptAccount/deleteById/${id}`);
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
    customStore.backTitle = this.providerName;
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#OrderList {
}
</style>
