<template>
  <div id="LayoutContainer">
    <header class="l-header">
      <nav-bar />
    </header>
    <main class="l-content">
      <slider-bar class="l-content__slider" />
      <div class="l-content__main">
        <transition appear mode="out-in" name="fade-transform">
          <router-view :key="$route.fullPath" class="router-view" />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import SliderBar from "@/components/layout/slider-bar";
import NavBar from "@/components/layout/nav-bar";

export default {
  name: "Index",
  components: { NavBar, SliderBar },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
};
</script>

<style scoped lang="scss">
@import "~assets/scss/variables.scss";

#LayoutContainer {
  height: 100vh;
  .l-header {
    height: $navHeight;
  }
  .l-content {
    height: calc(100% - #{$navHeight});
    display: flex;
    align-items: stretch;
    &__slider {
      width: 200px;
      flex-shrink: 0;
    }
    &__main {
      flex: 1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .router-view {
    min-height: 100%;
  }
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.02s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
