import Vue from "vue";
import Component from "vue-class-component";

@Component({
  provide() {
    const vm = this as any;
    return {
      resetSearch: () => {
        vm.searchForm = {};
        vm.keyWords = "";
        vm.selectDict = {
          optionType: "",
          keyWords: "",
        };
        vm.selectDict2 = {
          optionType: "",
          keyWords: "",
        };
      },
    };
  },
})
export class TableMixin extends Vue {
  keyWords = "";
  loading = false;
  searchForm: any = {};
  selectDict = {
    optionType: "",
    keyWords: "",
  };
  tableData = [];
  pageObj = { num: 1, size: 10, total: 0 };

  get selectForm() {
    const { optionType, keyWords } = this.selectDict;
    // const needInteger = optionType.toLowerCase().includes("id");
    // const intKeyWords = isNaN(+keyWords) ? -1 : keyWords;
    return { [optionType]: keyWords };
  }
}
