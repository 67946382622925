import { ModelSync } from "vue-property-decorator";
import { Message } from "element-ui";
import { applyMetadata } from "vue-property-decorator/lib/helpers/metadata";
import { createDecorator } from "vue-class-component";

function isType(typeStr) {
  typeStr = typeStr.toLowerCase();
  return function (obj) {
    return Object.prototype.toString.call(obj).toLowerCase() === `[object ${typeStr}]`;
  };
}

export function times<T>(num: number, cb: (n: number) => T): T[] {
  return Array.from({ length: num }, (__, idx) => cb && cb(idx));
}

export function itemFromArr(arr: any[], key: string, val: any) {
  return arr.find((obj) => obj[key] === val) || null;
}

export function groupBy(arr: any[], key: string) {
  return arr.reduce((map, item) => {
    const mapK = item[key];
    map[mapK] || (map[mapK] = []);
    map[mapK].push(item);
    return map;
  }, {});
}

export function chunkArr(arr: any[], size: number) {
  const length = Math.ceil(arr.length / size);
  return Array.from({ length }, (__, idx) => arr.slice(idx * size, (idx + 1) * size));
}

export function uniqArr(arr: any[]) {
  return Array.from(arr.reduce((map, cur) => (map.has(cur) || map.set(cur, 1), map), new Map()).keys());
}

export function last(arr: any = []) {
  return [].concat(arr).slice(-1)[0];
}

export function shuffle([...arr]) {
  for (let i = 0; i < arr.length; i++) {
    const rIdx = Math.floor(Math.random() * arr.length);
    [arr[i], arr[rIdx]] = [arr[rIdx], arr[i]];
  }
  return arr;
}

export const isDate = isType("date");
export const isObject = isType("object");
export const isArray = isType("array");
export const isNumber = isType("number");
export const isNull = (val) => val == null;
export const isEmpty = (v) => {
  const emptyObj = isObject(v) && !Object.keys(v).length;
  const emptyArr = isArray(v) && !v.length;
  return v == null || emptyObj || (typeof v === "string" && !v);
};

export function defaultProp(obj) {
  return { default: () => obj };
}

export function VModelSync(options?) {
  if (options === void 0) {
    options = {};
  }
  // @ts-ignore
  return function (target, key) {
    applyMetadata(options, target, key);
    createDecorator(function (componentOptions, k) {
      (componentOptions.props || (componentOptions.props = {}))["value"] = options;
      (componentOptions.computed || (componentOptions.computed = {}))[k] = {
        get: function () {
          return this["value"];
        },
        set: function (value) {
          // @ts-ignore
          this.$emit("input", value);
        },
      };
    })(target, key);
  };
}

export function updateCfgOption(list, key: string, options) {
  list = JSON.parse(JSON.stringify(list));
  return list.map((item) => {
    if (item.name !== key) return item;
    item.config.options = options;
    return item;
  });
}

export function formatOptions(list, labelK: string, valueK: string) {
  return list.map((item) => ({ label: item[labelK], value: item[valueK] }));
}

export function createFormData(configList: any[], defaultData = {}) {
  return configList.reduce((obj, item) => {
    const isNum = item.type === "vjNumber";
    const isTextInput = item.cmp === "textInput";
    let defaultVal: any;
    if (item.names)
      defaultVal = item.names.map((name, idx) => {
        let curCmpIsNum = isNum;
        if (isTextInput) {
          const textCmpSplit = item.config.text.match(/number|input|date/g) || [];
          if (textCmpSplit[idx] !== "number") curCmpIsNum = false;
        }
        const { defaultVal = [] } = item;
        const defaultCfgVal = defaultVal[idx];
        return isNull(defaultData[name]) ? defaultCfgVal || (curCmpIsNum ? 0 : "") : defaultData[name];
      });
    else defaultVal = defaultData[item.name];
    defaultVal == null && (defaultVal = item.defaultVal);
    obj[item.name] = defaultVal == null ? (isNum ? 0 : "") : defaultVal;
    return obj;
  }, {});
}

export function createFormRule(formData, configList: any[]) {
  return configList.reduce((obj, item) => {
    if (item.noCheck) return obj;
    if (!checkVisible(formData, item.show)) return obj;
    obj[item.name] = [{ required: true, message: "请配置上方的配置项", trigger: "blur" }];
    return obj;
  }, {});
}

/** 游戏平台表单 ***/
export function genFormRule(configList: any[]) {
  return configList.reduce((obj, item) => {
    item?.rule && (obj[item.prop] = [].concat(item.rule).map((item) => Object.assign({ required: true }, item)));
    return obj;
  }, {});
}

export function checkVisible(this: any, formData, showCondition = []) {
  const [key, val = []] = showCondition;
  if (!key) return true;
  if (Array.isArray(key)) return showCondition.every((condition) => checkVisible.call(this, formData, condition));
  if (Array.isArray(val)) return val.some((cur) => formData[key] === cur);
  return formData[key] === val;
}

/** 游戏平台表单 ***/
export function formatFormData(configList: any[], formData = {}) {
  return configList.reduce((obj, item) => {
    // const [sKey, val] = item.show || [];
    // if (sKey && formData[sKey] !== val) return obj;
    const key = item.name;
    const value = formData[key];
    const isNum = item.type === "vjNumber";
    if (item.names) item.names.forEach((name, idx) => (obj[name] = value ? value[idx] : isNum ? 0 : ""));
    else obj[key] = value;
    return obj;
  }, {});
}

export function ruleFormValid(ruleFormVDom, errMsg = "请填写完整表单！") {
  return new Promise((resolve, reject) => {
    ruleFormVDom.validate((valid) => {
      if (valid) return resolve(1), valid;
      setTimeout(() => Message.error(errMsg));
      reject("请填写完整表单!");
      return valid;
    });
  });
}

export function cloneDeep(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function hookLoading() {
  return function (target, propertyKey: string, descriptor: PropertyDescriptor) {
    const oldFn = target[propertyKey];
    descriptor.value = function (this: any, num = 1, size = 10) {
      this.loading = true;
      oldFn
        .call(this, num, size)
        .then((obj) => {
          this.pageObj = { num, size, total: obj?.page?.totalElements };
        })
        .finally(() => (this.loading = false));
    };
  };
}

export function hookSubmit() {
  return function (target, propertyKey: string, descriptor: PropertyDescriptor) {
    const oldFn = target[propertyKey];
    descriptor.value = function (this: any, ...args) {
      this.loading = true;
      oldFn.apply(this, args).finally(() => (this.loading = false));
    };
  };
}

export function excludeEmpty(obj, ...keys) {
  return Object.entries(obj).reduce((map, [k, v]) => {
    if (keys.includes(k) || isEmpty(v)) return map;
    map[k] = isObject(v) ? excludeEmpty(v) : v;
    return map;
  }, {});
}

export function randomRange(begin, end) {
  return Math.floor(Math.random() * (end - begin + 1) + begin);
}

export function randomItem(arr: any[]) {
  const total = arr.reduce((acc, [__, num]) => acc + num, 0);
  const collection = arr.reduce((acc, [item, num]) => {
    const count = (num / total) * 100;
    return acc.concat(times(count, () => item));
  }, []);
  const randomIdx = Math.floor(Math.random() * collection.length);
  return collection[randomIdx];
}

export function genFormOptions(labels: any[] = [], addition = 0, visibleVal?) {
  return {
    options: labels.reduce((acc, label, idx) => (label ? acc.concat({ label, value: idx + addition }) : acc), []),
    visibleVal,
  };
}

export function genFormOptionsWithAll(labels: any[], addition = 0, visibleVal?) {
  return {
    options: [
      { label: "全部", value: "" },
      ...labels.reduce((acc, label, idx) => (label ? acc.concat({ label, value: idx + addition }) : acc), []),
    ],
    visibleVal,
  };
}
export function genFormOptionsWithDictArr(dictArr: any[], visibleVal?) {
  return {
    options: [{ label: "全部", value: "" }, ...dictArr.reduce((acc, [num, label]) => acc.concat({ label, value: num }), [])],
    visibleVal,
  };
}

export function genTbFmt(options: string[], addition = 0) {
  return function (row, item, val) {
    return options[val + addition];
  };
}

export function genTbValuesFmt(options: any[][]) {
  return function (row, item) {
    const [label] = options.find(([k, v]) => v === row[item.property]) || [];
    return label || "暂无";
  };
}

export function genMenuCfg(arr, basePath, icons?: string[]) {
  return arr.map(([icon, title, path, cfg]) => {
    const isChildren = Array.isArray(cfg);
    if (icons) {
      const randomIdx = Math.floor(Math.random() * icons.length);
      icon = icons[randomIdx];
    }
    return {
      icon,
      name: title,
      path: basePath + path,
      children: isChildren ? genMenuCfg(cfg, basePath, icons) : undefined,
      groupName: isChildren ? undefined : cfg,
    };
  });
}

export function once(fn) {
  let toggle = false;
  return function (this: any) {
    if (toggle) return;
    toggle = true;
    fn.apply(this, arguments);
  };
}
