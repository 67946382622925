<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/删除"
    id="RolePop">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
      <template #menuIdList>
        <el-tree ref="tree" default-expand-all :data="menuTree" show-checkbox node-key="id" :props="defaultProps"> </el-tree>
      </template>
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { RoleForm, UserForm } from "@/config/edit-forms";
import { excludeEmpty, hookSubmit } from "@/components/vj-lib/utils";
import { ElTree } from "element-ui/types/tree";

@Component({})
export default class RolePop extends Mixins(FormMixin) {
  @Ref() tree!: ElTree<any, any>;
  showPop = false;
  cfgList = RoleForm;
  menuTree = [];
  defaultCheckedKeys = [];
  defaultProps = {
    children: "child",
    label: "name",
    disabled: (data) => !data.status,
  };

  showPopup(data = {}, isNew) {
    this.tree && this.tree.setCheckedKeys([]);
    this.showPop = true;
    this.isNew = isNew;
    this.id = data["id"];
    this.formData = data;
    this.initRoleMenus();
  }

  async initRoleMenus() {
    if (!this.id) return;
    const res = await requestByPost(`sysMenu/getRoleMenuIdsByRoleId/${this.id}`);
    res.data.forEach((id) => this.tree.setChecked(id, true, false));
  }
  async initMenuTree() {
    const res = await requestByPost(`sysMenu/getMenuTree`);
    this.menuTree = res.data;
  }

  @hookSubmit()
  async onSubmit() {
    const ids = this.tree.getCheckedNodes(false, true).map(({ id }) => id);
    await requestByPost(
      `sysRole/saveUpdate`,
      excludeEmpty({
        ...this.formData,
        id: this.isNew ? null : +this.id,
        menuIdList: ids,
      })
    );
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  created() {
    this.initMenuTree();
  }
}
</script>

<style lang="scss">
@import "~assets/common.scss";
#RolePop {
  .el-tree-node.is-expanded > .el-tree-node__children {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
