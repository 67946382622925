import { FormConfig } from "@/components/vj-lib/types";
import { genFormOptions } from "@/components/vj-lib/utils";

export const SysFM: FormConfig = [
  { name: "maxPerAppointments", label: "单用户最大预约数", type: "vjNumber", config: { min: 1 } },
];
export const SchoolFM: FormConfig = [
  { name: "name", label: "名称", type: "vjInput" },
  { name: "intro", label: "介绍", type: "vjInput", config: { type: "textarea", rows: 3 } },
  { name: "address", label: "地址", type: "vjInput", config: { type: "textarea", rows: 3 } },
  { name: "banners", label: "Banner图", type: "vjUpload", cmp: "multiple", noCheck: true },
  {
    name: "status",
    label: "状态",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["关闭", "开启"]),
    defaultVal: 1,
  },
  {
    name: "initialMembers",
    label: "虚拟会员数",
    type: "vjNumber",
    config: { min: 0 },
    defaultVal: 1,
    underSuffix: "仅用于小程序展示时加上的数字",
  },
  {
    name: "increaseFactor",
    label: "会员增长因子",
    type: "vjNumber",
    config: { min: 1 },
    defaultVal: 1,
    underSuffix: "仅用于小程序展示时加上的数字，每增加一个用户时乘以N倍显示",
  },
  // { name: "courseIds", label: "课程(多选)", type: "vjSelect", config: { ...genFormOptions([]), multiple: true } },
  { name: "richText", label: "展示信息", type: "vjInput", cmp: "rich", noCheck: true },
];
export const LessonFM: FormConfig = [
  { name: "name", label: "名称", type: "vjInput" },
  {
    name: "address",
    label: "显示地址",
    type: "vjInput",
    config: { type: "textarea", rows: 3 },
    underSuffix: "仅用于小程序显示",
  },
  {
    name: "realAddress",
    label: "导航地址",
    type: "vjInput",
    config: { type: "textarea", rows: 3 },
    underSuffix: `真实导航的位置,需要填写完整地址具体门牌号,如:"江苏省南京市六合区新华路388号"`,
  },
  { name: "schoolId", label: "实践所", type: "vjSelect", config: genFormOptions([]) },
  {
    name: "schoolCourseId",
    label: "实践所课程",
    type: "vjSelect",
    config: genFormOptions([]),
    underSuffix: "需要先选择实践所后再选择课程",
  },
];
export const UserForm: FormConfig = [
  { name: "nickname", label: "昵称", type: "vjInput" },
  { name: "username", label: "账号", type: "vjInput" },
  { name: "password", label: "密码", type: "vjInput" },
  { name: "roleId", label: "角色", type: "vjSelect", config: genFormOptions([]) },
];
export const AccountForm: FormConfig = [
  { name: "loginName", label: "用户名", type: "vjInput" },
  { name: "password", label: "密码", type: "vjInput", noCheck: true },
  { name: "roleList", label: "角色", type: "vjSelect", config: { multiple: true, options: [] } },
  { name: "status", label: "使用状态", type: "vjSelect", config: genFormOptions(["禁用", "启用"]) },
];
export const AccountNewForm: FormConfig = [
  { name: "receiptAccount", label: "收款账号", type: "vjInput" },
  { name: "payDomain", label: "前端域名", type: "vjInput", noCheck: true },
  { name: "apiDomain", label: "后端域名", type: "vjInput", noCheck: true },
  { name: "appId", label: "appid", type: "vjInput" },
  { name: "appSecret", label: "appkey", type: "vjInput", config: { maxlength: 5000 } },
  { name: "appMerchant", label: "app账号", type: "vjInput", config: { maxlength: 5000 }, noCheck: true },
  {
    name: "limitDayAmount",
    names: ["limitDayAmount"],
    label: "日限额",
    type: "vjNumber",
    cmp: "textInput",
    config: { text: "number CNY", max: 999999999999999, maxlength: 15 },
    noCheck: true,
  },
  {
    name: "limitMonthAmount",
    names: ["limitMonthAmount"],
    label: "月限额",
    type: "vjNumber",
    cmp: "textInput",
    config: { text: "number CNY", max: 999999999999999, maxlength: 15 },
    noCheck: true,
  },
  { name: "sortOrder", label: "排序", type: "vjNumber" },
  {
    name: "status",
    label: "启用状态",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["禁用", "启用"]),
    defaultVal: 1,
  },
];
export const RoleForm: FormConfig = [{ label: "角色名称", name: "name", type: "vjInput" }, { slotName: "menuIdList" }];
export const PermissionForm: () => FormConfig = () => [
  { name: "type", label: "类型", type: "vjSelect", config: genFormOptions(["目录", "菜单"]), defaultVal: 0 },
  { name: "name", label: "名称", type: "vjInput" },
  { name: "pid", label: "父级", type: "vjSelect", config: genFormOptions(), show: ["type", 1] },
  {
    name: "status",
    label: "显示",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["隐藏", "显示"]),
    defaultVal: 1,
  },
  { name: "permissions", label: "权限", type: "vjInput", defaultVal: 0 },
  { name: "href", label: "页面", type: "vjSelect", config: genFormOptions(), show: ["type", 1] },
  { name: "sort", label: "排序", type: "vjNumber" },
];

export const AppForm: FormConfig = [
  { name: "name", label: "应用名称", type: "vjInput" },
  { name: "remark", label: "应用描述", type: "vjInput" },
  {
    name: "status",
    label: "启用状态",
    type: "vjSelect",
    config: genFormOptions(["禁用", "启用"]),
    cmp: "radio",
    defaultVal: 1,
  },
  { name: "image", label: "应用图片", type: "vjUpload" },
];
export const SysPayForm: FormConfig = [
  { name: "sortOrder", label: "排序", type: "vjNumber", defaultVal: 999 },
  { name: "paymentType", label: "服务商", type: "vjSelect", config: genFormOptions([]) },
  { name: "name", label: "支付名称", type: "vjInput" },
  { name: "subPaymentType", label: "支付编码", type: "vjInput", noCheck: true },
  { name: "uniqueCode", label: "唯一标识", type: "vjInput" },
  {
    name: "servicePriceDesc",
    label: "手续费说明",
    type: "vjInput",
  },
  {
    name: "countryCodes",
    label: "可用地区",
    type: "vjInput",
    config: { placeholder: "前后用,隔开 例如：,CN,USA," },
    noCheck: true,
  },
  {
    name: "currencies",
    label: "可用货币",
    type: "vjInput",
    config: { placeholder: "前后用,隔开 例如：,USD,AED," },
    noCheck: true,
  },
  {
    name: "minLimitAmount",
    names: ["minLimitAmount", "maxLimitAmount", "limitCurrency"],
    label: "单笔限额",
    type: "vjNumber",
    cmp: "textInput",
    config: { text: "number≤金额≤number 货币input", max: 999999999999999, maxlength: 15 },
    noCheck: true,
  },

  { name: "remark", label: "支付描述", type: "vjInput", noCheck: true },
  {
    name: "isSupportRefund",
    label: "退款支持",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["不支持", "支持"]),
    defaultVal: 1,
  },
  {
    name: "payExpireMinutes",
    names: ["payExpireMinutes"],
    label: "关单时间",
    type: "vjNumber",
    cmp: "textInput",
    config: { text: "numbermin" },
  },
  {
    name: "status",
    label: "上架状态",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["下架", "上架"]),
    defaultVal: 1,
  },
  { name: "image", label: "支付图标", type: "vjUpload" },
  {
    name: "receiptAccountIds",
    label: "收款账号",
    type: "vjSelect",
    config: { ...genFormOptions([]), multiple: true },
    defaultVal: [],
  },
];

export const AppPayForm: FormConfig = [
  { name: "sortOrder", label: "排序", type: "vjNumber", defaultVal: 999 },
  { name: "paymentType", label: "服务商", type: "vjSelect", config: genFormOptions([]) },
  { name: "sysPaymentTypeId", label: "支付名称", type: "vjSelect", config: genFormOptions([]) },
  {
    name: "countryCodes",
    label: "可用地区",
    type: "vjInput",
    config: { placeholder: "前后用,隔开 例如：,CN,USA," },
    noCheck: true,
  },
  {
    name: "currencies",
    label: "可用货币",
    type: "vjInput",
    config: { placeholder: "前后用,隔开 例如：,USD,AED," },
    noCheck: true,
  },
  {
    name: "minLimitAmount",
    names: ["minLimitAmount", "maxLimitAmount", "limitCurrency"],
    label: "单笔限额",
    type: "vjNumber",
    cmp: "textInput",
    config: { text: "number≤金额≤number 货币input", max: 999999999999999, maxlength: 15 },
    noCheck: true,
  },
  {
    name: "receiptAccountIds",
    label: "收款账号：",
    type: "vjSelect",
    config: { options: [{ label: "使用系统账号", value: 0 }, ...genFormOptions([]).options], multiple: true },
  },
  {
    name: "status",
    label: "上架状态",
    type: "vjSelect",
    config: genFormOptions(["禁用", "启用"]),
    cmp: "radio",
    defaultVal: 1,
  },
];
export const BusinessForm: FormConfig = [
  { name: "name", label: "商户名称", type: "vjInput" },
  { name: "enterpriseName", label: "企业名称", type: "vjInput" },
  { name: "mobile", label: "联系人", type: "vjInput" },
  {
    name: "status",
    label: "启用状态",
    cmp: "radio",
    type: "vjSelect",
    config: genFormOptions(["禁用", "启用"]),
    defaultVal: 1,
  },
];
export const CurrencyFM: FormConfig = [
  { label: "排序", name: "sortOrder", type: "vjNumber" },
  { label: "货币名称", name: "name", type: "vjInput" },
  { label: "货币简称", name: "currency", type: "vjInput" },
  { label: "地区简称", name: "countryCodes", type: "vjInput", config: { placeholder: "多个用英文逗号隔开如,CN,US," } },
  { label: "货币符号", name: "mark", type: "vjInput" },
  { label: "汇率", name: "exchangeRate", type: "vjNumber", config: { precision: 4 } },
  {
    label: "订单金额",
    name: "isSupportPoint",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["手续费补整", "支持2位小数"]),
  },
  { label: "状态", name: "status", type: "vjSelect", config: genFormOptions(["隐藏", "显示"]) },
];
export const MenuForm: FormConfig = [
  { name: "name", label: "菜单名称", type: "vjInput" },
  { name: "path", label: "菜单路径", type: "vjInput", config: { disabled: true } },
  { name: "roles", label: "角色", type: "vjSelect", config: { ...genFormOptions([]), multiple: true } },
];
export const ArticleFM: FormConfig = [
  { name: "title", label: "标题", type: "vjInput" },
  { name: "author", label: "作者", type: "vjInput" },
  { name: "cover", label: "封面图", type: "vjUpload", noCheck: true },
  { name: "content", label: "文章内容", type: "vjInput", cmp: "rich" },
];
export const SchoolCourseFM: FormConfig = [
  { name: "courseId", label: "课程", type: "vjSelect", config: genFormOptions([]) },
  { name: "schoolId", label: "实践所", type: "vjSelect", config: genFormOptions([]) },
  {
    name: "shouldSendMsg",
    label: "开班前消息推送",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["关闭", "开启"]),
    defaultVal: 1,
  },
  {
    name: "status",
    label: "状态",
    type: "vjSelect",
    cmp: "radio",
    config: genFormOptions(["关闭", "开启"]),
    defaultVal: 1,
  },

  { name: "times", label: "课时数", type: "vjNumber", config: { min: 1 }, defaultVal: 1 },
  { name: "beginDate", label: "开启日期", type: "vjDate", config: { type: "datetime" } },
  { name: "dayOfTheWeek", label: "每周几开始", defaultVal: 1, type: "vjNumber", underSuffix: "课时开启的时间" },
  {
    name: "startAt",
    names: ["startAt", "finishedAt"],
    label: "起止时间",
    type: "vjDate",
    cmp: "time",
    defaultVal: [],
    underSuffix: "课时开启的时间",
  },
];

export const CourseFM: FormConfig = [
  { name: "name", label: "名称", type: "vjInput" },
  { name: "intro", label: "介绍", type: "vjInput", config: { type: "textarea", rows: 3 } },
  {
    name: "maxCourseAppointments",
    label: "最大预约人数",
    type: "vjNumber",
    config: { min: 1 },
    defaultVal: 30,
  },
  { name: "icon", label: "图标", type: "vjUpload", noCheck: true },
];

export const ToolsFM: FormConfig = [
  {
    name: "content",
    label: "消息内容",
    type: "vjInput",
    config: { type: "textarea", rows: 3 },
    noCheck: true,
    underSuffix: "点击下方发送消息按钮 才会发送该内容",
  },
];
