<template>
  <div id="VJDate">
    <el-time-picker
      v-if="isTypeOf('time')"
      is-range
      v-model="fakeValue"
      range-separator="至"
      v-bind="timeConfig"
      placeholder="选择时间范围" />
    <el-date-picker v-else v-model="fakeValue" v-bind="dateConfig" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { FormMixin } from "./mixins/form-mixin";
import { Prop } from "vue-property-decorator";
import { VDate, VInput } from "./config/form";
import { VModelSync } from "@/components/vj-lib/utils";

@Component
export default class VJDate extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJDate";
  @Prop() cmp!: string;
  @Prop() disabledDate?: Function;
  @VModelSync() fakeValue!: string;
  get dateConfig() {
    return this.combineWith<VDate.configType>(VDate.date);
  }
  get timeConfig() {
    return this.combineWith<VDate.configType>(VDate.time);
  }
  created() {}
}
</script>

<style scoped lang="scss">
#VJDate {
}
</style>
