<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/删除"
    id="AccountNewPopup">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { AccountForm, AccountNewForm, UserForm } from "@/config/edit-forms";
import { excludeEmpty, hookSubmit } from "@/components/vj-lib/utils";

@Component({})
export default class AccountNewPopup extends Mixins(FormMixin) {
  showPop = false;
  cfgList = AccountNewForm;
  formData = {} as any;
  @Prop()
  paymentType!: string;

  showPopup(data = {} as any) {
    this.showPop = true;
    this.id = data["id"];
    this.isNew = !this.id;
    this.formData = data;
  }

  @hookSubmit()
  async onSubmit() {
    await warnConfirm();
    await requestByPost(
      `pcReceiptAccount/edit`,
      excludeEmpty({
        id: this.isNew ? undefined : +this.id,
        paymentType: this.paymentType,
        ...this.formData,
      })
    );
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#AccountNewPopup {
}
</style>
