import { constantRoutes, FULL_ROUTES } from "@/router";
import Vue from "vue";
import { requestByGet, requestByPost } from "@/utils/request";
import NProgress from "nprogress";
import { Message } from "element-ui";

/**
 * 判断每个路由的 meta 中的 roles 是否允许当前用户的权限
 * 如果路由没有 roles 则所有权限都可以访问
 * admin角色所有路由都可以访问
 */
function hasPermission(accessMenus, route, roleName) {
  if (roleName === "admin") return true;
  //如果是不存在menu里的页面 可以允许访问 因为菜单上没有权限可以配置
  if (!route.meta.showMenu) return true;
  return accessMenus.some(({ path }) => path.includes(route.path));
}

/**
 * 递归过滤删选出能访问的路由
 */
function filteredCurRoleCanAccessRoutes(routes) {
  const res: any[] = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (tmp.children) tmp.children = filteredCurRoleCanAccessRoutes(tmp.children);
    res.push(tmp);
  });
  return res;
}

/***
 * 生产路由
 */
export function generateRoutes() {
  let accessedRoutes = [...constantRoutes] as any;
  //如果服务端控制角色权限 此处需要给FULL_ROUTES 每个路由的meta打上角色
  accessedRoutes[0].children = filteredCurRoleCanAccessRoutes(FULL_ROUTES);
  return accessedRoutes;
}

class RouteGuard {
  whiteList = /login|register/;
  loginPath = "/login";
  //方便用于页面上展示
  store = Vue.observable({ canActivePath: [] as any });

  getToken() {
    return localStorage.getItem("Authorization");
  }

  cleanToken() {
    localStorage.removeItem("Authorization");
  }
  async initMenusByUser() {
    const res = await requestByPost(`sysMenu/getUserMenusByUserId`);
    this.store.canActivePath = res.data;
  }
  isPathCanBeAccess(path, pathList) {
    if (path === "/") return true;
    return pathList.some(({ href, child }) => href === path || this.isPathCanBeAccess(path, child));
  }
  /****
   * 每个用户会有很多角色 特定路由限定角色才能访问
   */
  async activateHandle(from, to, next, router) {
    if (to.path === this.loginPath) return next("/");
    //如果有角色可以直接访问路由 这个路由都是由该用户的角色生成的 所以不用操心该角色能不能访问
    if (!this.store.canActivePath.length) await this.initMenusByUser();
    //如果没有角色 需要获取该用户的角色 并生成路由
    try {
      // if (!this.isPathCanBeAccess(to.path, this.store.canActivePath)) throw new Error("没权限");
      next();
      NProgress.done();
    } catch (error) {
      Message.error("暂无权限访问该页面！");
      next(from);
      NProgress.done();
    }
  }

  forbiddenHandle(from, to, next) {
    if (to.path.match(this.whiteList)) return next();
    return next({
      path: `${this.loginPath}?redirect=${to.path}`,
      replace: true,
    });
  }
}
export const routeGuard = new RouteGuard();
