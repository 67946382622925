<template>
  <div id="SysPayList">
    <body-container>
      <section slot="features">
        <el-button @click="editPopup.showPopup({}, 1)">新增</el-button>
      </section>
      <search-form-container @search="onSearch">
        <div slot="input" class="search-input-slot">
          <el-select class="search-input-slot__options" size="small" v-model="selectDict.optionType">
            <el-option value="name" label="支付名称" />
            <el-option value="subPaymentType" label="支付编码" />
            <el-option value="uniqueCode" label="唯一标识" />
            <el-option value="id" label="支付方式ID" />
          </el-select>
          <el-input size="small" clearable class="search-input-slot__cmp" v-model="selectDict.keyWords" placeholder="请输入" />
        </div>
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <vj-table
        @switch="onSwitch"
        @loadTable="onSearch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :table-data="tableData">
        <template #action="item">
          <text-button @click="editPopup.showPopup(item)" text="编辑" />
          <text-button @click="editPopup.showPopup(item, 1)" text="复制" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <sys-pay-edit-popup ref="editPopup" @refresh="onSearch(pageObj.num, pageObj.size)" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByDel, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { SysPayWayTB, UserTB } from "@/config/tables";
import { SysPaySH } from "@/config/search-forms";
import SysPayEditPopup from "@/components/popup/sys-pay-edit-popup.vue";

export const title = "系统支付列表";
@Component({
  components: { SysPayEditPopup, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class SysPayList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  cfgList = SysPayWayTB;
  searchCfg = SysPaySH();
  selectDict: any = { optionType: "name", keyWords: null };
  @Ref() editPopup!: SysPayEditPopup;

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign(this.selectForm, this.searchForm);
    const res = await requestByPost(`pcPaymentType/pageList/${num}/${size}`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }

  async onSwitch(val, name, { id }) {
    await requestByPost(`pcPaymentType/updateStatus`, { id, status: +val });
    this.$message.success("操作成功！");
    return this.onSearch(this.pageObj.num, this.pageObj.size);
  }

  @hookLoading()
  async del(id) {
    await warnConfirm();
    await requestByDel(`pcPaymentType/${id}`);
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#SysPayList {
}
</style>
