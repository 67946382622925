import { render, staticRenderFns } from "./pay-way-list.vue?vue&type=template&id=70780534&scoped=true"
import script from "./pay-way-list.vue?vue&type=script&lang=ts"
export * from "./pay-way-list.vue?vue&type=script&lang=ts"
import style0 from "./pay-way-list.vue?vue&type=style&index=0&id=70780534&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70780534",
  null
  
)

export default component.exports