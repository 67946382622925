// 获取拼图验证
import { requestByGetPure } from "@/utils/request";
import { BACKEND_PATH_PURE } from "@/config/properties";

export const getVerifyImg = (verificationEnums, uuid) => {
  return requestByGetPure(`${BACKEND_PATH_PURE}/passport/verificationSource/${verificationEnums}`, {}, { headers: { uuid } });
};

// 拼图验证
export const postVerifyImg = (verificationEnums, xPos, uuid) => {
  return requestByGetPure(
    `${BACKEND_PATH_PURE}/passport/verificationSource/${verificationEnums}/${xPos}`,
    {},
    { headers: { uuid } }
  );
};
