<template>
  <div id="AppList">
    <body-container>
      <section slot="features" style="margin-right: auto">
        <em style="margin: 0 0.2em">-</em><em style="color: #409eff">{{ appName }}</em>
      </section>
      <search-form-container @search="onSearch">
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <section class="c-cards">
        <el-card v-for="(item, i) in tableData" :key="i" class="c-cards__item">
          <div slot="header" class="cards-header">
            <img class="cards-header__logo" :src="getPic(item.image)" alt="" />
            <strong class="cards-header__name">{{ item.name }}</strong>
            <p class="cards-header__desc">{{ item.remark }}</p>
            <el-tag class="cards-header__tag" type="success" v-if="item.status">使用中</el-tag>
            <el-tag class="cards-header__tag" type="danger" v-else>已停用</el-tag>
          </div>
          <nav class="cards-nav">
            <div class="cards-nav__item">统计</div>
            <div class="cards-nav__item" @click="editPopup.showPopup(item)">设置</div>
            <div class="cards-nav__item" @click="$pushRoute({ path: 'pay-way-list', query: { appId: item.id } })">支付方式</div>
          </nav>
          <section class="cards-form">
            <label class="cards-form__label">应用ID</label>
            <p class="cards-form__val active-able" @click="copyText(item.appId)">{{ item.appId }}</p>
          </section>
          <section class="cards-form">
            <label class="cards-form__label">应用KEY</label>
            <p class="cards-form__val active-able" @click="copyText(item.appSecret)">{{ item.appSecret }}</p>
          </section>
        </el-card>
        <el-card class="c-cards__item">
          <div class="cards-tips active-able" @click="editPopup.showPopup({}, 1)">新建应用</div>
        </el-card>
      </section>
    </body-container>
    <app-edit-popup ref="editPopup" :merchant-id="merchantId" @refresh="onSearch" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Prop, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { UserTB } from "@/config/tables";
import { AppSH, FundsSH } from "@/config/search-forms";
import AppEditPopup from "@/components/popup/app-edit-popup.vue";

export const showMenu = false;
export const title = "应用列表";
@Component({
  components: { AppEditPopup, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class AppList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  @Ref("editPopup") editPopup!: AppEditPopup;
  @Prop() merchantId!: string;
  @Prop() appName!: string;
  searchCfg = AppSH;
  selectDict: any = { optionType: "name", keyWords: null };

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign(this.selectForm, this.searchForm, { merchantId: this.merchantId });
    const res = await requestByPost(`pcApp/pageList/1/99999`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }

  async onSwitch(val, name, { id }) {
    await requestByPost(`sys/saveAdmin`, { id, status: +val });
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  @hookLoading()
  async del(id) {
    await warnConfirm();
    await requestByPost(`sys/delAdmin/${id}`);
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#AppList {
  .c-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    &__item {
      height: 230px;
      position: relative;
    }
    .cards-tips {
      @include absoluteFull;
      @include flex-center;
      cursor: pointer;
      background: #f1f1f1;
      user-select: none;
      font-size: 26px;
    }
    .cards-header {
      @include alignCenter;
      &__logo {
        @include round(30px);
        flex-shrink: 0;
      }
      &__name {
        font-size: 16px;
        word-break: keep-all;
        flex-shrink: 0;
        margin: 0 10px;
      }
      &__desc {
        margin-right: auto;
        color: #706f6f;
        @include t-overflow;
      }
    }
    .cards-nav {
      margin-top: -12px;
      margin-bottom: 30px;
      display: flex;
      font-size: 16px;
      height: 40px;
      position: relative;
      &:after {
        content: "";
        @include absoluteXCenter;
        bottom: -10px;
        width: 100%;
        border-bottom: 1px solid #ebeef5;
      }

      &__item {
        flex: 1;
        cursor: pointer;
        @include flex-center;
        &:nth-child(2) {
          border: 1px solid #ebeef5;
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .cards-form {
      margin-top: 1em;
      @include alignCenter;
      &__label {
        font-size: 14px;
        margin-right: 1em;
        width: 4em;
      }
      &__val {
        user-select: none;
        @include t-overflow;
        background: #f1f0f0;
        flex: 1;
        height: 20px;
        line-height: 20px;
        padding: 0 0.5em;
        cursor: pointer;
      }
    }
  }
}
</style>
