import Vue from "vue";
import Router from "vue-router";
import RouterContainer from "@/components/layout/LayoutContainer.vue";
import Login from "@/pages/common/login.vue";
import { routeGuard } from "@/utils/permission";
import NProgress from "nprogress";
import { updatePageTitle } from "@/utils/logic";

Vue.use(Router);

//根据目录自动生成的所有路由
export const FULL_ROUTES = (() => {
  const componentCtx = require.context("@/pages", true, /\.vue$/);
  const viewPaths = componentCtx.keys().filter((name) => !name.startsWith("./common"));
  return viewPaths.map((path) => {
    const viewModule = componentCtx(path);
    const { title, roles, showMenu = true } = viewModule;
    return {
      path: path.replace(/\.\/(.*)\.vue$/g, "$1"),
      component: (r) => r(viewModule),
      meta: { title, roles, showMenu },
      props: (route) => route.query,
    };
  });
})();
//固定存在的路由
export const constantRoutes = [
  {
    path: "/",
    component: RouterContainer,
    children: FULL_ROUTES,
  },
  {
    path: "/login",
    component: Login,
  },
];
//生成新的路由对象
export const createRouter = (routes = constantRoutes) =>
  new Router({
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes,
  } as any) as any;
//默认路由对象
const router = createRouter();

/***
 * 如果没有登录则不能访问其他路由
 */
function initRouterInterceptor(router) {
  router.beforeEach((to, from, next) => {
    NProgress.start();
    updatePageTitle(to.meta.title);
    const userToken = routeGuard.getToken();
    if (!userToken) return routeGuard.forbiddenHandle(from, to, next);
    return routeGuard.activateHandle(from, to, next, router);
    // return next();
  });
  router.afterEach(() => {
    NProgress.done();
  });
}

initRouterInterceptor(router);
export default router;
