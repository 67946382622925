import { MessageBox } from "element-ui";

export function warnConfirm(msg = "确定该操作吗？") {
  return MessageBox.confirm(msg, { type: "warning" });
}
export function updatePageTitle(title = "管理后台") {
  document.title = title;
}

export function updateFormSelectOption(formCfg: any[], key: string, options: any[]) {
  const target = formCfg.find(({ name }) => name === key);
  if (!target) return formCfg;
  if (!target.config) target.config = {};
  target.config.options = options;
  return formCfg;
}

export function fmtMinutes(StatusMinute) {
  var day = parseInt((StatusMinute / 60 / 24) as any);
  var hour = parseInt(((StatusMinute / 60) % 24) as any);
  var min = parseInt((StatusMinute % 60) as any);
  StatusMinute = "";
  if (day > 0) {
    StatusMinute = day + "天";
  }
  if (hour > 0) {
    StatusMinute += hour + "小时";
  }
  if (min > 0) {
    StatusMinute += parseFloat(min as any) + "分钟";
  }
  return StatusMinute;
}
export function getCountryCodes(countryCodes) {
  return `,${countryCodes
    .split(",")
    .filter((v) => v != "")
    .join(",")},`;
}
