<template>
  <div id="VJUpload">
    <el-upload
      :action="action"
      v-if="isTypeOf('multiple')"
      v-bind="combineConfig"
      multiple
      :headers="headers"
      list-type="picture-card"
      :file-list="fileList"
      :on-success="onUploadFileList.bind(this)"
      :on-remove="onRemoveFileList.bind(this)">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-upload
      :action="action"
      :show-file-list="false"
      :headers="headers"
      v-else
      v-bind="combineConfig"
      v-loading="loading"
      class="avatar-uploader"
      :before-upload="onBeforeUpload"
      :on-error="onUploadError"
      :on-success="onUpload">
      <img
        :style="{ background: bg != null && '#000' }"
        v-if="!Array.isArray(fakeValue) && fakeValue"
        alt=""
        :src="getPic(fakeValue)"
        class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { FormMixin } from "./mixins/form-mixin";
import { VInput, VUpload } from "./config/form";
import { Prop, Watch } from "vue-property-decorator";
import { Message } from "element-ui";
import emitter from "element-ui/src/mixins/emitter";
import { defaultProp, VModelSync } from "@/components/vj-lib/utils";
import { once } from "./utils";

@Component({
  mixins: [emitter],
})
export default class VJUpload extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJUpload";
  loading = false;
  @Prop() cmp!: string;
  @Prop() bg!: boolean;
  @VModelSync(defaultProp([])) fakeValue!: any;
  fileList = [] as any;
  get combineConfig() {
    return this.combineWith<VUpload.configType>(VUpload.config);
  }
  get action() {
    return this.$uploadPath || "";
  }
  get headers() {
    const header = {};
    const Authorization = localStorage.getItem("Authorization");
    const gameId = localStorage.getItem("gameId");
    Authorization && (header["Authorization"] = Authorization);
    gameId && (header["gameId"] = gameId);
    return header;
  }
  onRemoveFileList(removed, files) {
    this.fakeValue = files.map(({ response, rawUrl }) => response?.data || rawUrl);
  }
  onUploadFileList(res, file, files) {
    if (!res?.data) return this.onUploadError();
    this.fakeValue = files.map(({ response, rawUrl }) => response?.data || rawUrl);
  }
  onUploadError() {
    this.loading = false;
    return Message.error("上传失败！");
  }
  onUpload(res) {
    if (!res?.data) return this.onUploadError();
    this.fakeValue = res.data;
    this.loading = false;
    this.$emit("uploaded");
  }
  onBeforeUpload() {
    this.loading = true;
    const pass = this.combineConfig.beforeUpload ? this.combineConfig.beforeUpload.apply(this, arguments as any) : true;
    pass || (this.loading = false);
    return pass;
  }
  valueChange(val) {
    if (!val) return;
    if (Array.isArray(val)) this.fileList = val.map((url) => ({ url: this.getPic(url), rawUrl: url }));
    this["dispatch"]("ElFormItem", "el.form.blur", [this.fakeValue]);
  }
  created() {
    this.$watch("fakeValue", once(this.valueChange.bind(this)));
  }
}
</script>

<style lang="scss">
#VJUpload {
  .el-upload-list__item {
    transition: none !important;
  }
  .avatar-uploader {
    display: inline-block;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
    object-fit: contain;
  }
}
</style>
