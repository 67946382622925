import Vue from "vue";

let BRANCH_NAME = "master";
console.log(BRANCH_NAME);
BRANCH_NAME = BRANCH_NAME.split(/\s/)[1] || BRANCH_NAME;
BRANCH_NAME = BRANCH_NAME.replace("origin/", "");
//根据当前分支名 设定当前主环境参数
const ENV_NAME = (() => {
  switch (BRANCH_NAME) {
    case "master":
    case "main":
      return "prod";
    case "test":
      return "test";
    default:
      return "dev";
  }
})();
export const IS_PROD = ENV_NAME === "prod";
const PROD_DOMAIN = location.href.includes("console.xiao-haijing")
  ? `//adminapi.xiao-haijing.com/adminApi`
  : "//adminpay-api.cosmostop.com/adminApi";
export const BACKEND_PATH = IS_PROD ? PROD_DOMAIN : `//dev-topup-admin-api.fulugame.cn/adminApi`;
export const BACKEND_PATH_PURE = BACKEND_PATH.replace("/adminApi", "");
export const uploadPath = `${BACKEND_PATH.replace("/adminApi", "")}/upload/uploadToCDN`;
export const customStore = Vue.observable({
  userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") as any) : {},
  paymentServices: [],
  exchangeRates: [],
  backTitle: "",
  countryCodes: [],
});
