<template>
  <div id="FundsList">
    <body-container>
      <section slot="features">
        <el-button @click="exportFile()">导出</el-button>
      </section>
      <search-form-container @search="onSearch">
        <div slot="input" class="search-input-slot">
          <el-select class="search-input-slot__options" size="small" v-model="selectDict.optionType">
            <el-option value="orderId" label="订单号" />
            <el-option value="merchantOrderId" label="商户订单号" />
            <el-option value="payOrderId" label="支付订单号" />
            <el-option value="receiptAccount" label="收款账号" />
            <el-option value="id" label="资金流水ID" />
          </el-select>
          <el-input size="small" clearable class="search-input-slot__cmp" v-model="selectDict.keyWords" placeholder="请输入" />
        </div>
        <vj-form hide-foot :form-data.sync="searchForm" inline :cfg-list="searchCfg" />
      </search-form-container>
      <vj-table @loadTable="onSearch" :page-obj="pageObj" :loading="loading" :cfg-list="cfgList" :table-data="tableData">
      </vj-table>
    </body-container>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Prop, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { defaultHeaders, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { excludeEmpty, hookLoading } from "@/components/vj-lib/utils";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { FundsTB, UserTB } from "@/config/tables";
import { FundsSH } from "@/config/search-forms";
import axios from "axios";
import { BACKEND_PATH } from "@/config/properties";

export const showMenu = false;

export const title = "资金流水";
@Component({
  components: { UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class FundsList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  @Prop() merchantId!: string;
  cfgList = FundsTB;
  searchCfg = FundsSH();
  selectDict: any = { optionType: "id", keyWords: null };

  async initSearchDict() {
    const data = await requestByPost(`pcApp/pageList/1/99999`, { merchantId: this.merchantId });
    const options = data.data.map(({ name, id }) => ({ label: name, value: id }));
    updateFormSelectOption(this.searchCfg, "appId", options);
  }

  async exportFile() {
    const params = Object.assign(this.selectForm, this.searchForm);
    const res = await axios.post(`${BACKEND_PATH}/pcMerchantAmount/export`, excludeEmpty(params), {
      headers: defaultHeaders(),
      responseType: "blob",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(res.data);
    a.download = "报表.xlsx";
    a.click();
  }

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign(this.selectForm, this.searchForm, { merchantId: this.merchantId });
    const res = await requestByPost(`pcMerchantAmount/pageList/${num}/${size}`, excludeEmpty(params));
    this.tableData = res.data;
    return res;
  }

  created() {
    this.initSearchDict();
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#FundsList {
}
</style>
