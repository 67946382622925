import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { defaultProp } from "@/components/vj-lib/utils";

@Component({})
export class FormMixin extends Vue {
  cmp!: string;
  @Prop(defaultProp({})) config: any;
  propName!: any;
  get bindListeners() {
    const fnWithArgs =
      (fn) =>
      (...args) =>
        fn.apply(this, args.concat(this.propName));
    return Object.entries(this.$listeners).reduce((obj, [key, fn]) => {
      obj[key] = Array.isArray(fn) ? fn.map((f) => fnWithArgs(f)) : fnWithArgs(fn);
      return obj;
    }, {});
  }
  isTypeOf(cmpName) {
    return cmpName === this.cmp;
  }
  combineWith<T>(...config): T {
    return Object.assign({}, ...config, this.config);
  }
}
