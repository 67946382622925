<template>
  <div id="app" element-loading-background="rgba(0, 0, 0, 0.8)" v-loading.fullscreen="!loaded">
    <router-view v-if="loaded" />
  </div>
</template>
<script>
import { requestByPost } from "@/utils/request";
import { customStore } from "@/config/properties";

export default {
  data() {
    return {
      loaded: false,
    };
  },
  methods: {},
  async created() {
    const token = localStorage.getItem("Authorization");
    if (!token) return (this.loaded = true);
    await this.initPreData();
    this.loaded = true;
  },
};
</script>
<style lang="scss">
@import "./assets/theme.scss";
#app {
}
</style>
