<template>
  <div id="RoleList">
    <body-container>
      <section slot="features">
        <el-button @click="rolePop.showPopup()">新增</el-button>
      </section>
      <vj-table @loadTable="onSearch" :page-obj="pageObj" :loading="loading" :cfg-list="cfgList" :table-data="tableData">
        <template #action="item">
          <text-button @click="rolePop.showPopup(item)" text="编辑" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <role-pop ref="rolePop" @refresh="onSearch(pageObj.num, pageObj.size)" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { hookLoading } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { RoleTB, UserTB } from "@/config/tables";
import RolePop from "@/components/popup/role-pop.vue";

export const title = "角色列表";
@Component({
  components: { RolePop, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class RoleList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  cfgList = RoleTB;
  @Ref() rolePop!: RolePop;
  searchCfg = [];
  selectDict: any = { optionType: "name", keyWords: null };
  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign(this.selectForm, this.searchForm);
    const res = await requestByPost(`sysRole/pageList/${num}/${size}`, params);
    this.tableData = res.data;
    return res;
  }

  @hookLoading()
  async del(id) {
    await warnConfirm();
    await requestByPost(`sysRole/deleteById/${id}`);
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#RoleList {
}
</style>
