import { ElInput } from "element-ui/types/input";
import { ElInputNumber } from "element-ui/types/input-number";
import { ElSelect } from "element-ui/types/select";
import { ElDatePicker } from "element-ui/types/date-picker";
import { ElTimePicker } from "element-ui/types/time-picker";
import { ElRadio } from "element-ui/types/radio";
import { ElUpload } from "element-ui/types/upload";
import Vue from "vue";
import { ElCascader } from "element-ui/types/cascader";
import { ElCheckbox } from "element-ui/types/checkbox";
import { ElCheckboxGroup } from "element-ui/types/checkbox-group";
type Option = { label: string; value: any };

export namespace VInput {
  export const config: Partial<ElInput> = {
    placeholder: "请输入",
    maxlength: 128,
  };
  export type configType = Partial<ElInput>;
  export type cfg = {
    type?: "vjInput";
    cmp?: "rich";
    config?: configType;
  };
}
export namespace VFile {
  export const config: Partial<ElUpload> = {};
  export type configType = Partial<ElUpload>;
  export type cfg = {
    type?: "vjFile";
    config?: configType;
  };
}
export namespace VNumber {
  export const config: Partial<ElInputNumber> = {
    min: 0,
    max: 99999,
    size: "small",
    precision: 0,
    controlsPosition: "right",
  };
  export type configType = Partial<ElInputNumber> & { text?: string; maxlength?: number };
  export type cfg = {
    type?: "vjNumber";
    cmp?: "range" | "textInput";
    config?: configType;
  };
}
export namespace VRadioCmp {
  export const config = {
    placeholder: "请输入",
    min: 0,
    max: 99999,
  };
  export type configType = Partial<ElInputNumber> & Partial<ElRadio> & { options: Option[] } & { visibleVal: number };
  export type cfg = {
    type?: "vjRadioCmp";
    cmp?: "date";
    config: configType;
  };
}
export namespace VSelect {
  export const config = {
    placeholder: "请选择",
  };
  export type configType = { options: Option[] } & (
    | Partial<ElSelect>
    | Partial<ElCheckboxGroup>
    | Partial<ElRadio>
    | Partial<ElCascader>
  );
  export type cfg = {
    type?: "vjSelect";
    cmp?: "radio" | "checkbox" | "cascader" | "switch";
    config: configType;
  };
}
export namespace VUpload {
  export const config = {};
  export type configType = Partial<ElUpload>;
  export type cfg = {
    type?: "vjUpload";
    cmp?: "multiple";
    config?: configType;
  };
}
export namespace VDate {
  export const date: Partial<ElDatePicker> = {
    type: "datetime",
    valueFormat: "yyyy-MM-dd HH:mm:ss",
    startPlaceholder: "开始时间",
    endPlaceholder: "结束时间",
    placeholder: "选择日期",
  };
  export const time = {
    startPlaceholder: "开始时间",
    endPlaceholder: "结束时间",
    valueFormat: "HH:mm:ss",
  };
  export type configType = Partial<ElDatePicker> | Partial<ElTimePicker>;
  export type cfg = {
    type?: "vjDate";
    cmp?: "time";
    config?: configType;
  };
}
export const DefaultFormStyle = { labelPosition: "left" };
