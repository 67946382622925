import { RawLocation } from "vue-router";
import { DeepFlatten } from "@/utils/tshelper";
import { Vue } from "vue/types/vue";
import { customStore } from "@/config/properties";
import { requestByPost } from "@/utils/request";

const mixin = {
  methods: {
    copyText(this: any, str) {
      this.$copyText(str);
      this.$message.success("复制成功");
    },
    hasModifier(defaultClass, modifier, flag) {
      return flag ? `${defaultClass} ${defaultClass}--${modifier}` : defaultClass;
    },
    $replaceRoute(this: Vue, route: RawLocation) {
      return this.$router.replace(route);
    },
    $popRoute(this: Vue) {
      this.$router.go(-1);
    },
    $pushRoute(this: Vue, route: RawLocation) {
      return this.$router.push(route);
    },
    windowReload() {
      window.location.reload();
    },
    hasRoute(path: string) {
      return this["$router"].matcher.match(path).matched.length;
    },
    getPic(url) {
      const isFullPath = ["http", "data"].some((uri) => url.startsWith(uri)) || url.startsWith("/_nuxt");
      return isFullPath ? url : `https://cdn.itangka.com${url}`;
    },
    async initPreData() {
      const res = await Promise.allSettled([
        requestByPost(`pcPaymentCategory/allList`),
        requestByPost(`countryCodes/allList`),
        requestByPost(`exchangeRate/pageList/1/99999`),
      ]);
      const [{ data: paymentServices }, { data: countryCodes }, { data: exchangeRates }] = res.map((item) => item["value"] ?? {});
      customStore.paymentServices = paymentServices ?? [];
      customStore.exchangeRates = exchangeRates ?? [];
      customStore.countryCodes = countryCodes ?? [];
    },
  },
  computed: {
    customStore() {
      return customStore;
    },
  },
};
export default function CommonPlugin(Vue) {
  return Vue.mixin(mixin);
}
const mixinData = { ...mixin };
type CommonMixinType = DeepFlatten<typeof mixinData>;
export { CommonMixinType };
