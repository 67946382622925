<template>
  <div id="PermissionList">
    <body-container>
      <section slot="features">
        <el-button @click="permissionPop.showPopup()">新增</el-button>
      </section>
      <vj-table
        @loadTable="onSearch"
        @switch="onSwitch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :pagination="false"
        :table-data="tableData"
        :style-config="attributeConfig">
        <template #id>
          <el-table-column min-width="200px" align="center" label="Id">
            <template v-slot="{ row }">
              <div class="table-id">
                <i class="el-icon-plus"></i>
                <p>{{ row.id }}</p>
              </div>
            </template>
          </el-table-column>
        </template>
        <template #action="item">
          <text-button @click="permissionPop.showPopup(item)" text="编辑" />
          <text-button @click="permissionPop.showPopup(item, 1)" text="新增" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <permission-pop :menu-list="tableData" @refresh="onSearch()" ref="permissionPop" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import { Mixins, Prop, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByPost, requestByGet, requestByDel } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import { PermissionTB } from "@/config/tables";
import PermissionPop from "@/components/popup/permission-pop.vue";
import { warnConfirm } from "@/utils/logic";
import { excludeEmpty, hookSubmit } from "@/components/vj-lib/utils";

export const title = "权限列表";
@Component({
  components: { PermissionPop, TextButton, BodyContainer },
})
export default class PermissionList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  name = "PermissionList";
  cfgList = PermissionTB;
  attributeConfig = {
    "row-key": "id",
    "tree-props": { children: "child", hasChildren: "hasChildren" },
    // "default-expand-all":true,
  };
  @Ref() permissionPop!: PermissionPop;

  @hookSubmit()
  async del(id) {
    await warnConfirm();
    await requestByDel(`sysMenu/${id}`);
    this.$message.success("操作成功！");
    this.onSearch();
  }

  @hookSubmit()
  async onSearch() {
    const res = await requestByPost(`sysMenu/getMenuTree`);
    this.tableData = res.data;
    return res;
  }

  async onSwitch(val, name, { id, child = [] }) {
    if (!+val) {
      await Promise.all(child.map(({ id }) => requestByPost(`sysMenu/saveUpdate`, { id, status: +val })));
    }
    await requestByPost(`sysMenu/saveUpdate`, { id, status: +val });
    this.$message.success("操作成功！");
    return this.onSearch();
  }
  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#PermissionList {
  .table-id {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
