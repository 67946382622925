<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增货币/汇率"
    id="ActivityPublishDialog">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" :cfg-list="cfgList" :form-data.sync="formData"> </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByPost } from "@/utils/request";
import { hookSubmit } from "@/components/vj-lib/utils";
import { getCountryCodes, warnConfirm } from "@/utils/logic";
import { CurrencyFM } from "@/config/edit-forms";

@Component({})
export default class CurrencyPopup extends Mixins(FormMixin) {
  showPop = false;
  cfgList = CurrencyFM;
  private editId;

  onShow(data: any = {}) {
    this.showPop = true;
    this.formData = data;
    this.editId = data.id;
  }

  @hookSubmit()
  async onSubmit() {
    await warnConfirm();
    let { countryCodes }: any = this.formData;
    countryCodes = getCountryCodes(countryCodes);
    await requestByPost(`exchangeRate/edit`, { ...this.formData, countryCodes, id: this.editId });
    this.$emit("refresh");
    this.showPop = false;
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#CurrencyPopup {
}
</style>
