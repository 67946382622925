import { TableConfig } from "@/components/vj-lib/types";
import { genFormOptions, genTbFmt } from "@/components/vj-lib/utils";
import { customStore } from "@/config/properties";
import dayjs from "dayjs";
import { fmtMinutes } from "@/utils/logic";

function paymentTypeFmt({ paymentType }) {
  const target: any = customStore.paymentServices.find(({ type }) => type === +paymentType) ?? {};
  return target.name;
}

function appPayWayPropFmt(row, { property }) {
  return (row.appId ? row[property] : row.pcPaymentType?.[property]) || "全部";
}

export const MenuTB: TableConfig = [
  { propName: "name", label: "菜单名称" },
  { propName: "path", label: "路径" },
  {
    propName: "xx",
    label: "可访问角色",
    cmp: "html",
    formatter({ roles = [] }) {
      return roles.reduce((acc, { name }) => `${acc}<br/>${name}`, "").slice(5);
    },
  },
];
export const OrderTB: TableConfig = [
  { propName: "createdAt", label: "创建时间", config: {}, colStyle: { align: "left" } },
  {
    propName: "name",
    label: "订单信息",
    cmp: "html",
    colStyle: { align: "left" },
    formatter({ orderId, status, rmbPrice, currentPrice, currencyType }) {
      const statusDict = [
        [-2, "支付失败"],
        [-1, "已取消"],
        [0, "待支付"],
        [1, "已支付"],
        [5, "退款中"],
        [6, "退款成功"],
        [7, "退款失败"],
        [8, "支付超时"],
      ];
      const [__, statusStr] = statusDict.find(([statusNum]) => status === statusNum) ?? [];
      return `订单号：${orderId}<br/>
订单状态：${statusStr}<br/>
实付金额：${currentPrice}${currencyType}<br/>
人民币金额：${rmbPrice}`;
    },
  },
  {
    propName: "name",
    label: "商户信息",
    cmp: "html",
    colStyle: { align: "left" },
    formatter({ merchantOrderId, merchantName, appName, goodsName }) {
      return `商户订单号：${merchantOrderId}<br/>
商户名称：${merchantName}<br/>
应用名称：${appName}<br/>
商品名称：${goodsName}`;
    },
  },
  {
    propName: "name",
    label: "支付信息",
    cmp: "html",
    colStyle: { align: "left" },
    formatter({ payOrderId, paymentTypeName, receiptAccount, paymentTypeStr, servicePrice }) {
      return `支付订单号：${payOrderId}<br/>
支付服务商：${paymentTypeStr}<br/>
支付名称：${paymentTypeName}<br/>
收款账号：${receiptAccount}<br/>`;
    },
  },
  {
    propName: "name",
    label: "用户信息",
    cmp: "html",
    colStyle: { align: "left" },
    formatter({ payerId, ip, regionAddress, payerName, phone, email, payerCardNum }) {
      return `用户账号：${payerId ?? ""}<br/>
下单IP：${ip}（${regionAddress ?? ""}）<br/>
姓名：${payerName ?? ""}<br/>
手机号：${phone ?? ""}<br/>
邮箱：${email ?? ""}<br/>
卡号：${payerCardNum ?? ""}`;
    },
  },
];
export const AccountProviderTB: TableConfig = [
  { propName: "id", label: "服务商ID" },
  { propName: "name", label: "服务商名称" },
  { propName: "totalAmount", label: "累计收款金额CNY" },
];
export const AccountListTB: TableConfig = [
  { propName: "sortOrder", label: "排序" },
  { propName: "receiptAccount", label: "收款账号" },
  { propName: "limitDayAmount", label: "日收款限额" },
  { propName: "dayAmount", label: "今天累计收款" },
  { propName: "limitMonthAmount", label: "月收款限额" },
  { propName: "monthAmount", label: "本月累计收款" },
  { propName: "totalAmount", label: "总收款CNY" },
  { propName: "payDomain", label: "前端域名" },
  { propName: "status", label: "状态", cmp: "switch" },
];
export const PermissionTB: TableConfig = [
  { propName: "id", label: "ID" },
  { propName: "name", label: "名称" },
  { propName: "type", label: "类型", formatter: genTbFmt(["目录", "菜单", "按钮"]) },
  { propName: "status", label: "是否显示", cmp: "switch" },
  { propName: "sort", label: "排序" },
  { propName: "updatedAt", label: "更新时间" },
];
export const CurrencyTB: TableConfig = [
  { propName: "id", label: "序号" },
  { propName: "name", label: "货币名称" },
  { propName: "currency", label: "货币简称" },
  {
    propName: "countryCodes",
    label: "地区简称",
    formatter: ({ countryCodes }) => {
      countryCodes = countryCodes || "";
      return countryCodes
        .split(",")
        .filter((v) => v != "")
        .join(",");
    },
  },
  { propName: "mark", label: "货币符号" },
  { propName: "exchangeRate", label: "汇率(货币1:RMB)" },
  { propName: "status", label: "状态", formatter: genTbFmt(["隐藏", "显示"]) },
];
export const FundsTB: TableConfig = [
  { propName: "createdAt", label: "创建时间" },
  { propName: "id", label: "资金流水ID" },
  { propName: "appName", label: "应用名称" },
  {
    propName: "amount",
    label: "变动金额(人民币)",
    formatter: ({ amount, type }) => {
      const amountSymbol = ["+", "-", "-"][type];
      return `${amountSymbol} ${amount}`;
    },
  },
  { propName: "beforeAmount", label: "变动前余额(人民币)" },
  { propName: "afterAmount", label: "变动后余额(人民币)" },
  { propName: "type", label: "流水类型", formatter: genTbFmt(["收款", "退款", "提现"]) },
  { propName: "orderId", label: "订单号" },
  { propName: "merchantOrderId", label: "商户订单号" },
  { propName: "paymentType", label: "支付服务商", formatter: paymentTypeFmt },
  { propName: "receiptAccount", label: "收款账号" },
  { propName: "payOrderId", label: "支付单号" },
];
export const RoleTB: TableConfig = [
  { propName: "id", label: "ID" },
  { propName: "name", label: "角色" },
  { propName: "createdAt", label: "创建时间" },
  { propName: "updatedAt", label: "更新时间" },
];
export const AccountTB: TableConfig = [
  { propName: "createdAt", label: "创建时间" },
  { propName: "id", label: "账号ID" },
  { propName: "loginName", label: "用户名" },
  {
    propName: "roleList",
    label: "用户角色",
    formatter: ({ roleList }) => (roleList || []).map(({ name }) => name).join("、"),
  },
  { propName: "channelId", label: "账号类型", formatter: ({ channelId }) => (channelId ? "渠道账号" : "内部账号") },
  { propName: "status", label: "使用状态", cmp: "switch" },
];
export const UserTB: () => TableConfig = function (this: any) {
  return [
    { propName: "nickname", label: "昵称" },
    {
      propName: "username",
      label: "账号",
      customRender: (h, data) => {
        const { id, username } = data;
        return (
          <div>
            <p style={{ color: "red", cursor: "pointer" }} onClick={this.fuck}>
              {username}({id})
            </p>
            <el-button>fuck</el-button>
          </div>
        );
      },
    },
    { propName: "role.name", label: "角色名称" },
    { propName: "createAt", label: "创建日期" },
  ];
};
export const BusinessTB: () => TableConfig = function (this: any) {
  return [
    { propName: "id", label: "商户ID" },
    {
      label: "商户名称",
      customRender: (h, data) => {
        const { name, id } = data;
        return (
          <el-button onClick={() => this.nav2Page("app-list", { merchantId: id, appName: name })} type={"text"}>
            {name}
          </el-button>
        );
      },
    },
    {
      label: "余额(人民币)",
      customRender: (h, data) => {
        const { balance, id } = data;
        return (
          <el-button onClick={() => this.nav2Page("funds-list", { merchantId: id })} type={"text"}>
            {balance}
          </el-button>
        );
      },
    },
    { propName: "freezeAmount", label: "冻结金额(人民币)" },
    { propName: "totalAmount", label: "总收入(人民币)" },
    { propName: "status", label: "状态", cmp: "switch" },
  ];
};
export const SysPayWayTB: TableConfig = [
  { propName: "sortOrder", label: "排序" },
  { propName: "id", label: "支付方式ID" },
  { propName: "name", label: "支付名称" },
  { propName: "paymentType", label: "支付服务商", formatter: paymentTypeFmt },
  { propName: "uniqueCode", label: "唯一标识" },
  { propName: "countryCodes", label: "可用地区", formatter: ({ countryCodes }) => countryCodes || "全部" },
  { propName: "currencies", label: "可用货币", formatter: ({ currencies }) => currencies || "全部" },
  {
    propName: "minLimitAmount",
    label: "单笔限额",
    formatter: ({ minLimitAmount, maxLimitAmount }) => `${maxLimitAmount}>N>${minLimitAmount}`,
  },
  { propName: "servicePriceDesc", label: "手续费说明" },
  { propName: "image", label: "图标", cmp: "img" },
  { propName: "isSupportRefund", label: "退款支持", formatter: genTbFmt(["不支持", "支持"]) },
  { propName: "payExpireMinutes", label: "关单时间", formatter: ({ payExpireMinutes }) => fmtMinutes(payExpireMinutes) },
  { propName: "status", label: "状态", cmp: "switch" },
];
export const AppPayTB: TableConfig = [
  { propName: "id", label: "支付方式ID" },
  { propName: "sortOrder", label: "排序" },
  { propName: "name", label: "支付名称" },
  { propName: "paymentType", label: "支付服务商", formatter: paymentTypeFmt },
  { propName: "uniqueCode", label: "唯一标识" },
  {
    propName: "countryCodes",
    label: "可用地区",
    formatter: appPayWayPropFmt,
  },
  { propName: "currencies", label: "可用货币", formatter: appPayWayPropFmt },
  { propName: "servicePriceDesc", label: "手续费说明" },
  // {
  //     propName: "xx",
  //     label: "单笔限额",
  //     formatter: (row) => {
  //         const {maxLimitAmount, minLimitAmount} = row.appId ? row : row.pcPaymentType ?? {};
  //         return `${maxLimitAmount}>N>${minLimitAmount}`;
  //     },
  // },
  { propName: "status", label: "状态", cmp: "switch" },
];
