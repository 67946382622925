import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import CommonPlugin from "@/plugins/common";
import DirectivesPlugin from "@/plugins/directives";
import FiltersPlugin from "@/plugins/filters";
import VJForm from "@/components/vj-lib";
import { uploadPath } from "@/config/properties";
import "nprogress/nprogress.css";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(CommonPlugin);
Vue.use(DirectivesPlugin);
Vue.use(FiltersPlugin);
Vue.use(VJForm, {
  uploadPath: uploadPath,
});
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
console.log("更新版本2");
