<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/编辑"
    id="AppEditPopup">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { AppForm, UserForm } from "@/config/edit-forms";
import { hookSubmit } from "@/components/vj-lib/utils";

@Component({})
export default class AppEditPopup extends Mixins(FormMixin) {
  @Prop() merchantId!: string;

  showPop = false;
  cfgList = AppForm;

  showPopup(data = {}, isNew) {
    this.showPop = true;
    this.isNew = isNew;
    this.id = data["id"];
    this.formData = data;
  }

  async initSearchDict() {
    const data = await requestByGet(`sys/allRoleList`);
    const options = data.map(({ name, id }) => ({ label: name, value: id }));
    updateFormSelectOption(this.cfgList, "roleId", options);
  }

  @hookSubmit()
  async onSubmit() {
    await requestByPost(`pcApp/edit`, {
      ...this.formData,
      id: this.isNew ? null : +this.id,
      merchantId: this.merchantId,
    });
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  created() {}
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#AppEditPopup {
}
</style>
