<template>
  <div id="VJInput">
    <rich-text :upload-url="UploadUrl" v-if="isTypeOf('rich')" v-model="fakeValue" />
    <el-input v-else v-model="fakeValue" v-bind="combineConfig" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VInput } from "./config/form";
import RichText from "./rich-text.vue";
import { FormMixin } from "./mixins/form-mixin";
import { VModelSync } from "@/components/vj-lib/utils";

@Component({
  components: { RichText },
})
export default class VJInput extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJInput";
  @Prop() cmp!: string;
  @VModelSync() fakeValue!: string;
  UploadUrl = "asd";

  get combineConfig() {
    return this.combineWith<VInput.configType>(VInput.config);
  }
  created() {}
}
</script>

<style scoped lang="scss">
#VJInput {
}
</style>
