import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component
export class FormMixin extends Vue {
  id!: string;
  cfgList!: any[];
  loading = false;
  formData = {} as any;
  isNew: any = !this.id;
  cfgListGenerator?: () => any[];

  created() {
    if (!this.cfgListGenerator) return;
    this.cfgList = this.cfgListGenerator();
  }
}
