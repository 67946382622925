<template>
  <el-dialog
    :close-on-click-modal="false"
    width="900px"
    :visible.sync="showPop"
    v-loading="loading"
    title="新增/编辑"
    id="AppPayEditPopup">
    <vj-form :loading="loading" hide-reset @submit="onSubmit" class="vj-form" :cfg-list="cfgList" :form-data.sync="formData">
    </vj-form>
  </el-dialog>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { updateFormSelectOption, warnConfirm } from "@/utils/logic";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { FormMixin } from "@/plugins/mixins/form";
import { requestByGet, requestByPost } from "@/utils/request";
import { AppPayForm, UserForm } from "@/config/edit-forms";
import { cloneDeep, excludeEmpty, hookSubmit } from "@/components/vj-lib/utils";
import { customStore } from "@/config/properties";

@Component({})
export default class AppPayEditPopup extends Mixins(FormMixin) {
  @Prop() appId!: string;
  showPop = false;
  isEchoData = false;
  cfgList = AppPayForm;
  oldData = {};

  showPopup(data = {} as any, isNew) {
    this.showPop = true;
    this.isNew = isNew;
    this.oldData = cloneDeep(data);
    this.id = data["id"];
    this.isEchoData = !!this.id;
    if (!data.receiptAccountIds?.length) data.receiptAccountIds = [0];
    this.formData = data;
    this.initSearchDict();
  }

  async initSearchDict() {
    const options = customStore.paymentServices.map(({ type, name }) => ({ label: name, value: type }));
    updateFormSelectOption(this.cfgList, "paymentType", options);
  }

  completionFormKeySymbol(key) {
    const val = this.formData[key];
    if (!val || /^,(.*),$/g.test(val)) return;
    this.formData[key] = val.replace(/^,?(.*),?$/g, ",$1,");
  }

  //当服务商切换时 获取收款账号
  @hookSubmit()
  async changeReceiptAccountIdsHandle(val) {
    const res = await requestByPost(`pcReceiptAccount/getList`, { paymentType: val, status: 1 });
    const options = [{ label: "使用系统账号", value: 0 }].concat(
      res.data.map(({ receiptAccount, id }) => ({ label: receiptAccount, value: id }))
    );
    updateFormSelectOption(this.cfgList, "receiptAccountIds", options);
    //如果是回显状态 则不修改 仅将状态改变
    if (this.isEchoData) return (this.isEchoData = false);
    //每当切换服务商时将账号选择第一个  注意 回显时也会调用这个接口 这时候不能将其设置为第一个
    this.formData.receiptAccountIds = [0];
  }

  //获取支付名称
  async getSysPaymentTypeNamesHandle(paymentType) {
    const { data } = await requestByPost(`pcPaymentType/allList`, { paymentType });
    const options = data.map(({ id, name }) => ({ label: name, value: id }));
    const target = options.find(({ value }) => value === this.formData["sysPaymentTypeId"]);
    if (!target) this.formData["sysPaymentTypeId"] = null;
    updateFormSelectOption(this.cfgList, "sysPaymentTypeId", options);
  }

  @Watch("formData.paymentType")
  onPaymentTypeChange(paymentType) {
    if (!paymentType) return;
    this.getSysPaymentTypeNamesHandle(paymentType);
    this.changeReceiptAccountIdsHandle(paymentType);
  }

  @Watch("formData.sysPaymentTypeId")
  async updateFormDataBySysPaymentTypeId(sysPaymentTypeId) {
    if (!sysPaymentTypeId || this.id) return;
    const { id, appId, appSecret, appMerchant, receiptAccount, isSupportRefund, payExpireMinutes, ...data } = await requestByGet(
      `pcPaymentType/${sysPaymentTypeId}`
    );
    Object.assign(this.formData, data);
  }

  @hookSubmit()
  async onSubmit() {
    this.completionFormKeySymbol("countryCodes");
    this.completionFormKeySymbol("currencies");
    const { receiptAccountIds, ...restForm } = this.formData;
    await requestByPost(
      `pcAppPaymentType/edit`,
      excludeEmpty({
        ...this.oldData,
        ...restForm,
        receiptAccountIds: receiptAccountIds.filter(Boolean),
        id: this.isNew ? null : +this.id,
        pcAppId: this.appId,
      })
    );
    this.$message.success("操作成功！");
    this.$emit("refresh");
    this.showPop = false;
  }

  created() {}
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#AppPayEditPopup {
}
</style>
