import axios from "axios";
import { BACKEND_PATH, uploadPath } from "@/config/properties";
import { initHttpInterceptor } from "@/interceptor";
import { excludeEmpty } from "@/components/vj-lib/utils";

/*** ================初始化====================== ***/
export const defaultHeaders = () => {
  const header = {};
  const Authorization = localStorage.getItem("Authorization");
  Authorization && (header["Authorization"] = Authorization);
  return header;
};
const httpInstance = axios.create({
  baseURL: BACKEND_PATH,
});
initHttpInterceptor(httpInstance);

/*** ================请求====================== ***/
async function requestByGetPure(url, params = {}, config: any = {}) {
  if (!url) return;
  const res = await httpInstance.get(url, {
    params: excludeEmpty(params),
    ...config,
    headers: Object.assign(defaultHeaders(), config.headers || {}),
  });
  return res.data;
}

async function requestByUpload(obj, path) {
  const formData = Object.entries(obj).reduce((formData, [key, val]) => {
    formData.append(key, val as any);
    return formData;
  }, new FormData());
  const headers = {
    ...defaultHeaders(),
    "Content-Type": "multipart/form-data",
  };
  const res = await httpInstance.post(path, formData, { headers });
  return res.data.data;
}

async function requestByGet(url, params = {}, config = {}) {
  if (!url) return;
  const res = await httpInstance.get(url, {
    params: excludeEmpty(params),
    headers: defaultHeaders(),
    ...config,
  });
  return res.data.data;
}

async function requestByPost(url, data = {}, extraConfig = {} as any) {
  if (!url) return;
  const config = { ...extraConfig, headers: Object.assign(defaultHeaders(), extraConfig.headers) };
  const res = await httpInstance.post(url, data, config);
  return res.data;
}

async function requestByDel(url, params = {}, config = {}) {
  if (!url) return;
  const res = await httpInstance.delete(url, {
    params: excludeEmpty(params),
    headers: defaultHeaders(),
    ...config,
  });
  return res.data.data;
}

export { requestByGet, requestByPost, requestByGetPure, requestByUpload, requestByDel };
