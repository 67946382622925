<template>
  <div id="AccountList">
    <body-container>
      <section slot="features">
        <el-button @click="editPopup.showPopup()">新增</el-button>
      </section>
      <vj-table
        @loadTable="onSearch"
        @switch="onSwitch"
        :page-obj="pageObj"
        :loading="loading"
        :cfg-list="cfgList"
        :table-data="tableData">
        <template #action="item">
          <text-button @click="editPopup.showPopup(item)" text="编辑" />
          <text-button @click="editPopup.showPopup(item, 1)" text="复制" />
          <text-button @click="del(item.id)" text="删除" />
        </template>
      </vj-table>
    </body-container>
    <account-popup ref="editPopup" @refresh="onSearch" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByDel, requestByGet, requestByPost, requestByUpload } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import UploadBtn from "@/components/basic/upload-btn.vue";
import { hookLoading, hookSubmit } from "@/components/vj-lib/utils";
import { warnConfirm } from "@/utils/logic";
import { AccountTB, UserTB } from "@/config/tables";
import AccountPopup from "@/components/popup/account-popup.vue";

export const title = "账号管理";
@Component({
  components: { AccountPopup, UploadBtn, TextButton, SearchFormContainer, BodyContainer },
})
export default class AccountList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  cfgList = AccountTB;
  @Ref() editPopup!: AccountPopup;
  searchCfg = [];
  selectDict: any = { optionType: "name", keyWords: null };

  @hookSubmit()
  async del(id) {
    await warnConfirm();
    await requestByDel(`sysUser/${id}`);
    this.$message.success("操作成功！");
    this.onSearch();
  }

  @hookLoading()
  async onSearch(num?, size?) {
    const params = Object.assign({}, this.searchForm);
    const res = await requestByPost(`sysUser/pageList/${num}/${size}`, params);
    this.tableData = res.data;
    return res;
  }

  @hookSubmit()
  async onSwitch(val, name, { id }) {
    await requestByPost(`sysUser/updateStatus`, { id, status: +val });
    this.$message.success("操作成功！");
    return this.onSearch();
  }

  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";
#AccountList {
}
</style>
