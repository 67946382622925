<template>
  <label class="upload-btn"
    ><em>补充cdk</em
    ><input
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      style="display: none"
      @change="uploadFile"
      type="file" />
  </label>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { requestByUpload } from "@/utils/request";

@Component({})
export default class UploadBtn extends Vue implements ComponentOptions<Vue> {
  mounted() {}
  async uploadFile(event) {
    const [file] = event.target.files;
    event.target.value = null;
    this.$emit("upload", file);
  }
  async created() {}
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

.upload-btn {
  margin: 0 10px;
  color: #39ace7;
  cursor: pointer;
}
</style>
