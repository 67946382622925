<template>
  <div id="SearchFormContainer">
    <header class="c-header">
      <div class="c-header__label">
        <i class="el-icon-search c-header__icon" />
        <em class="vertical-middle">筛选搜索</em>
      </div>
      <div class="c-header__input">
        <slot name="input" />
      </div>
      <div class="header-feature">
        <el-button size="small" icon="el-icon-search" type="primary" @click="$emit('search')">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" type="warning" plain @click="onReset">重置 </el-button>
        <div class="header-feature__extra" @click="collapse = !collapse">
          <i :class="{ rotate: collapse }" class="header-feature__icon el-icon-arrow-down"></i>
          <em class="vertical-middle">筛选</em>
        </div>
      </div>
    </header>
    <el-collapse-transition>
      <main class="c-body" v-if="!collapse && $slots.default">
        <div class="c-body__container">
          <slot />
        </div>
      </main>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  name: "SearchFormContainer",
  components: {},
  props: { showReset: Boolean },
  inject: ["resetSearch"],
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    onReset() {
      this.resetSearch && this.resetSearch();
    },
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss" scoped>
@import "../../assets/common";
#SearchFormContainer {
  margin-bottom: 20px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0 3px 2px #dadada;
  .c-header {
    background: #eceef2;
    padding: 8px 10px;
    @include alignCenter;
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    justify-content: space-between;
    &__icon {
      color: #43c8b2;
      font-size: 26px;
      margin-right: 10px;
      vertical-align: middle;
    }
    &__input {
      width: 40%;
      @include alignCenter;
      .input-margin {
        margin-left: 20px;
      }
    }
    .header-feature {
      @include alignCenter;
      padding: 0 50px;
      &__extra {
        margin-left: 30px;
        cursor: pointer;
        user-select: none;
        .rotate {
          transform: rotate(180deg);
        }
        &:hover {
          color: #306eff;
        }
      }
      &__icon {
        font-size: 18px;
        vertical-align: middle;
        display: inline-block;
        transition: 0.1s linear;
      }
    }
  }
  .c-body {
    padding: 0 30px;
    background: white;
    overflow: hidden;
    &__container {
      margin: 26px 0 13px;
      /*::v-deep  .el-form-item {*/
      /*margin-bottom: 0;*/
      /*}*/
    }
  }
}
</style>
