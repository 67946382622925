import { FormConfig } from "@/components/vj-lib/types";
import { genFormOptions, genFormOptionsWithAll, genFormOptionsWithDictArr } from "@/components/vj-lib/utils";
import { customStore } from "@/config/properties";
import dayjs from "dayjs";

function genPaymentServiceOptions() {
  return {
    options: [
      { label: "全部", value: "" },
      ...customStore.paymentServices.map(({ type, name }) => ({
        label: name,
        value: type,
      })),
    ],
  };
}

function genCountryCodesOptions() {
  return {
    options: [
      { label: "全部", value: "" },
      ...customStore.countryCodes.map(({ code, country }) => ({
        label: country,
        value: code,
      })),
    ],
  };
}

function genExchangeRatesOptions() {
  return {
    options: [
      { label: "全部", value: "" },
      ...customStore.exchangeRates.map(({ currency, name }) => ({
        label: name,
        value: currency,
      })),
    ],
  };
}

export const BusinessSH: FormConfig = [
  {
    label: "状态",
    name: "status",
    type: "vjSelect",
    config: genFormOptionsWithAll(["禁用", "正常"]),
  },
];
export const SysPaySH: () => FormConfig = () => [
  {
    label: "支付服务商",
    name: "paymentType",
    type: "vjSelect",
    config: genPaymentServiceOptions(),
  },
  {
    label: "状态",
    name: "status",
    type: "vjSelect",
    config: genFormOptionsWithAll(["禁用", "正常"]),
  },
  {
    label: "可用地区",
    name: "countryCodes",
    type: "vjSelect",
    config: { ...genCountryCodesOptions(), filterable: true },
  },
  {
    label: "可用货币",
    name: "currencies",
    type: "vjSelect",
    config: { ...genExchangeRatesOptions(), filterable: true },
  },
];
export const FundsSH: () => FormConfig = () => [
  {
    label: "应用",
    name: "appId",
    type: "vjSelect",
    config: genFormOptionsWithAll([]),
  },
  {
    label: "类型",
    name: "type",
    type: "vjSelect",
    config: genFormOptionsWithAll(["收款", "退款", "提现"]),
  },
  {
    label: "支付服务商",
    name: "paymentType",
    type: "vjSelect",
    config: genPaymentServiceOptions(),
  },
  {
    label: "创建时间",
    name: "searchBeginTime",
    names: ["searchBeginTime", "searchEndTime"],
    type: "vjDate",
    config: {
      type: "datetimerange",
    },
  },
];
export const PayWaySH: () => FormConfig = () => [
  {
    label: "支付服务商",
    name: "paymentType",
    type: "vjSelect",
    config: genPaymentServiceOptions(),
  },
  {
    label: "状态",
    name: "status",
    type: "vjSelect",
    config: genFormOptionsWithAll(["禁用", "正常"]),
  },
  {
    label: "可用地区",
    name: "countryCodes",
    type: "vjSelect",
    config: { ...genCountryCodesOptions(), filterable: true },
  },
  {
    label: "可用货币",
    name: "currencies",
    type: "vjSelect",
    config: { ...genExchangeRatesOptions(), filterable: true },
  },
];
export const CurrencySH: () => FormConfig = () => [
  {
    label: "状态",
    name: "status",
    type: "vjSelect",
    config: genFormOptions(["隐藏", "显示"]),
  },
];
export const OrderSH: () => FormConfig = () => {
  let curMinDate = null;
  return [
    {
      label: "支付服务商",
      name: "paymentType",
      type: "vjSelect",
      config: genPaymentServiceOptions(),
    },
    {
      label: "支付名称",
      name: "paymentTypeNameList",
      type: "vjSelect",
      config: { options: [], multiple: true, filterable: true },
    },
    {
      label: "下单地区",
      name: "regionAddressList",
      type: "vjSelect",
      config: { ...genCountryCodesOptions(), multiple: true, filterable: true },
    },
    {
      label: "订单状态",
      name: "status",
      type: "vjSelect",
      config: genFormOptionsWithDictArr([
        [-2, "支付失败"],
        [-1, "已取消"],
        [0, "待支付"],
        [1, "已支付"],
        [5, "退款中"],
        [6, "退款成功"],
        [7, "退款失败"],
        [8, "支付超时"],
      ]),
    },
    {
      label: "创建时间",
      name: "searchBeginTime",
      names: ["searchBeginTime", "searchEndTime"],
      type: "vjDate",
      config: {
        type: "datetimerange",
        pickerOptions: {
          disabledDate(date) {
            if (!curMinDate) return false;
            return Math.abs(dayjs(date).diff(dayjs(curMinDate), "months")) > 1;
          },
          onPick: ({ minDate, maxDate }) => {
            curMinDate = minDate;
          },
        } as any,
      },
    },
    {
      label: "人民币金额",
      name: "xx",
      names: ["searchMinAmount", "searchMaxAmount"],
      type: "vjNumber",
      cmp: "range",
    },
  ];
};
export const AppSH: FormConfig = [
  {
    label: "状态",
    name: "status",
    type: "vjSelect",
    config: genFormOptionsWithAll(["禁用", "正常"]),
  },
];
