<template>
  <div id="VJNumber">
    <div v-if="isTypeOf('range')">
      <el-input-number v-model="fakeValue[0]" @change="onNumberChange" v-bind="combineConfig" />
      <span class="separator">至</span>
      <el-input-number v-model="fakeValue[1]" @change="onNumberChange" v-bind="combineConfig" />
    </div>
    <div class="number-row" v-else-if="isTypeOf('textInput')">
      <section class="number-row__item" v-for="(text, idx) in textRules" :key="idx" :class="{ offsetLeft: idx === 0 && !text }">
        <em class="number-row__label">{{ text }}</em>
        <!--                not include last cmp-->
        <template v-if="idx !== textRules.length - 1">
          <el-input-number
            v-if="textSplitor[idx] === 'number'"
            class="number-row__cmp"
            :value="fakeArr[idx]"
            @input="changeVal(idx, $event)"
            v-bind="combineConfig" />
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
            type="datetime"
            v-else-if="textSplitor[idx] === 'date'"
            class="number-row__cmp"
            :value="fakeArr[idx]"
            @input="changeVal(idx, $event)" />
          <el-input
            style="width: 100px"
            v-else
            class="number-row__cmp"
            :value="fakeArr[idx]"
            @input="changeVal(idx, $event)"
            v-bind="combineConfig" />
        </template>
      </section>
    </div>
    <el-input-number v-else v-model="fakeValue" v-bind="combineConfig" />
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component, { mixins } from "vue-class-component";
import { FormMixin } from "./mixins/form-mixin";
import { VInput, VNumber } from "./config/form";
import { Prop, Watch } from "vue-property-decorator";
import { VModelSync } from "@/components/vj-lib/utils";

@Component
export default class VJNumber extends mixins(FormMixin) implements ComponentOptions<Vue> {
  name = "VJNumber";
  @Prop() cmp!: string;
  @VModelSync() fakeValue!: string;
  @VModelSync() fakeArr!: any[];

  get combineConfig() {
    return this.combineWith<VNumber.configType>(VNumber.config) as any;
  }

  get textRules() {
    return this.combineConfig.text.split(/number|input|date/);
  }

  get textSplitor() {
    return this.combineConfig.text.match(/number|input|date/g) || [];
  }
  onNumberChange() {
    this.fakeValue = this.fakeValue;
  }

  changeVal(idx, val) {
    if (!Array.isArray(this.fakeArr)) return;
    this.fakeArr.splice(idx, 1, val);
    this.fakeArr = this.fakeArr;
  }
}
</script>

<style scoped lang="scss">
#VJNumber {
  .separator {
    margin: 0 10px;
  }
  .number-row {
    display: flex;
    align-items: center;
    &__item {
      display: flex;
      align-items: center;
      &.offsetLeft {
        margin-left: -10px;
      }
    }
    &__label {
      flex-shrink: 0;
      color: #3a3f51;
    }
    &__cmp {
      width: 170px;
      margin: 0 10px;
    }
  }
}
</style>
